import React, {Component} from 'react';
import "assets/scss/styles.scss";


class Personal extends Component {
    render() {
        return (
            <div>
				<svg version="1.1" id="Sobre_vos" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 503 274.2">
					<path class="st0" d="M97.3,114.3c-44.2,0-80,35.8-80,80s35.8,79.9,80,79.9s80-35.8,80-79.9S141.4,114.3,97.3,114.3z M250.8,23.1
	c-44.2,0-80,35.8-80,80s35.8,80,80,80s80-35.8,80-80S295,23.1,250.8,23.1z M403.1,113.8c-44.2,0-80,35.8-80,80s35.8,79.9,80,79.9
	s80-35.8,80-79.9C483.1,149.6,447.3,113.8,403.1,113.8z"/>
					<path class="st1" d="M97.4,121.9c-39.9,0-72.3,32.4-72.3,72.3s32.4,72.3,72.3,72.3s72.3-32.4,72.3-72.3S137.3,121.9,97.4,121.9z
	 M250.9,30.7c-39.9,0-72.3,32.4-72.3,72.3s32.4,72.3,72.3,72.3s72.3-32.4,72.3-72.3S290.8,30.7,250.9,30.7z M403.2,121.4
	c-39.9,0-72.3,32.4-72.3,72.3s32.4,72.3,72.3,72.3s72.3-32.4,72.3-72.3S443.2,121.4,403.2,121.4z"/>
					<path class="st2" d="M97.3,266.5c26.1,0,50.2-14.1,63-36.8c-2.1-6.9-11.3-36.9-13.6-40.9c-2.6-4.4-24.3-10.5-28.3-11.7
	s-21.3,0.5-21.3,0.5s-16.9-1.7-20.9-0.5s-25.8,7.2-28.3,11.7c-2.2,3.8-11.2,32.9-13.6,40.9C47.1,252.4,71.2,266.5,97.3,266.5z"/>
					<path class="st3" d="M56.1,223.7l-2,28.4c3,2.2,6.1,4.2,9.3,5.9l-6.6-34.3c0-0.2-0.2-0.4-0.4-0.3S56.1,223.5,56.1,223.7z
	 M138.4,225.1c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4l0,0l-6.3,33c3.1-1.7,6.1-3.6,9-5.7L138.4,225.1z"/>
					<path class="st4" d="M122.9,121.5c-0.5-1.9-2.9-1.3-2.9-1.3c0.3-3.1-1.5-12.4-5.2-16.2c-6.6-6.7-17.8-3.2-17.8-3.2
	s-11.1-3.5-17.8,3.3c-3.8,3.8-5.6,13.2-5.2,16.2c0,0-2.4-0.6-2.9,1.3s-0.9,4.7-0.1,6.8c0.9,2.2,3.2,4.1,3.9,5.6s2,13.7,5.2,17.4
	c1.9,2.2,2.8,5.2,2.4,8.1v14.4l-3.4,3.4c-0.2,0.2-0.2,0.5,0,0.7c0.1,0,0.1,0.1,0.2,0.1l12.2,4.9c3.5,1.4,7.4,1.4,10.9,0l12.2-4.9
	c0.3-0.1,0.4-0.4,0.3-0.7c0-0.1-0.1-0.1-0.1-0.2l-3.4-3.4v-14.4c-0.4-2.9,0.5-5.9,2.4-8.1c3.3-3.8,4.6-15.9,5.2-17.4s3-3.5,3.9-5.6
	C123.8,126.3,123.3,123.5,122.9,121.5z"/>
					<path class="st5" d="M92.8,134.6c0.9,0.6,3.5,3.1,4.1,4.6s1.7-1.2,0.2-2.8s-2.8-2.4-2.7-3.1s2.3-4.6,2.2-7.2
	c-0.2-2.6-1.1-3.2-1.4-1.5c0,0-0.7,4.5-1.9,6.6S91.8,134.1,92.8,134.6z M92.9,144.6c2.9,0.3,5.9,0.3,8.9,0c1-0.3,1.3-0.9,0.4-1.6
	c-0.6-0.4-0.7,0.3-1.8,0.4s-5.4,0.2-6.8,0.2S91.4,144.4,92.9,144.6L92.9,144.6z M97,153c-7.1,0-12.9,3.2-12.9,7.1s5.8,7.1,12.9,7.1
	s12.9-3.2,12.9-7.1S104.1,153,97,153z"/>
					<path class="st6" d="M89.8,115.3c-0.2,0-4.4-1.2-7.8,0.5c-0.4,0.3-0.6,0.9-0.2,1.4c0.2,0.2,0.4,0.4,0.7,0.4c0.1,0,0.3,0,0.4-0.1
	c2.7-1.4,6.3-0.4,6.4-0.4c0.6,0,1-0.5,1-1C90.3,115.8,90.1,115.5,89.8,115.3z M104.3,115.3c-0.5,0.3-0.6,0.9-0.3,1.4
	c0.2,0.3,0.5,0.5,0.8,0.5c0,0,3.6-1,6.4,0.4c0.1,0.1,0.3,0.1,0.4,0.1c0.5-0.1,0.9-0.6,0.8-1.1c0-0.3-0.2-0.5-0.4-0.7
	C108.7,114.1,104.5,115.3,104.3,115.3z M118.6,103.2c-2.6-2.2-6.4-3-7.3-5.1c-0.9-2-1.9-4.7-6.3-8.1c-4.4-3.4-11-3.8-16.9-2.2
	c-5.9,1.6-9.2,4.8-10,9.6c-0.8,4.8-3,5.5-4.7,8.1c-1.7,2.6-1.4,8.3-0.7,11c0.9,2.8,1.5,5.6,1.9,8.4v13.4c0,3.5,0.1,9.4,2.8,13.9
	c2.6,4.5,5.3,11.7,19.8,11.8c14.5-0.1,17.1-7.3,19.8-11.7c2.6-4.5,2.8-10.4,2.8-13.9V125c0-2.8,1.3-5.1,2.7-10.3
	C123.4,110.4,121.9,106,118.6,103.2z M103,149.7c-2-0.2-1.7-1.4-2.9-1.5h-6c-1.2,0.1-0.9,1.3-2.9,1.5c-2,0.2-2.6-1.1-2.7-2.5
	c0-1.5,0.5-3,1.5-4.2c0.9-1.2,2.4-1.2,4.6-1.2h4.9c2.2,0,3.7-0.1,4.6,1.2c1.1,1.2,1.6,2.7,1.6,4.2C105.6,148.6,105,149.9,103,149.7z
	 M115.2,140.1c-2.1,1.7-4.4,4-6.7,2.8c-2.3-1.2-3.6-3-6-3.1c-2.4-0.1-8.3-0.1-10.7,0c-2.4,0.1-3.7,1.9-6,3.1
	c-2.3,1.2-4.5-1.1-6.7-2.8c-2.1-1.7-2-3-2-6.5v-8.7c0,0-0.1-5.3-0.2-7.3c0-1.9,0.5-3.8,1.4-5.5c0.8-1.9,2.1-4.5,3.3-4.6
	c1.3-0.1,1.7,1.2,7.4,1.8c5.7,0.6,11.3-3.2,14.4-5.1c3.1-1.9,6.4-1.4,8.2,0.5c1.7,1.9,4.6,7.8,5.1,11.6c0.4,2.6,0.6,8.7,0.6,8.7
	l-0.1,8.6C117.2,137.1,117.3,138.4,115.2,140.1z M84.7,125.8c0,0.1,0.6,0.2,1.3,0.2s1.3-0.1,1.3-0.2s-0.6-0.2-1.3-0.2
	S84.7,125.7,84.7,125.8z M106.7,125.8c0,0.1,0.6,0.2,1.3,0.2s1.3-0.1,1.3-0.2s-0.6-0.2-1.3-0.2S106.7,125.7,106.7,125.8z"/>
					<path id="ojos1_2_" class="st6" d="M86,123.5c-0.7,0-1.3,1-1.3,2.3s0.6,2.3,1.3,2.3s1.3-1,1.3-2.3S86.7,123.5,86,123.5z M108,123.5
	c-0.7,0-1.3,1-1.3,2.3s0.6,2.3,1.3,2.3s1.3-1,1.3-2.3S108.7,123.5,108,123.5z M108,123.5L108,123.5L108,123.5L108,123.5z"/>
					<path class="st7" d="M121.4,119.3c-1.7-0.1-4.3,1.2-5.5,1.5c0,0-1.9-2.3-6.1-2.3c-3.1,0-6.1,0.9-8.7,2.6c-2.6,1.1-5.6,1.1-8.2,0
	c-2.5-1.8-5.6-2.7-8.7-2.6c-4.2,0-6.1,2.3-6.1,2.3c-1.2-0.3-3.8-1.5-5.5-1.5s-1.6,2.2-1.6,2.2c0.6-0.2,1.3-0.3,1.9-0.1
	c0.7,0.3,4.2,2.2,4.2,2.2c0,1.3,0.1,2.7,0.3,4c0.3,2.2,1.7,4.2,3.7,5.2c2.9,1,6,1,8.8,0c2.9-1.2,4.3-4.8,5-6.1
	c0.8-1.1,2.3-1.5,3.5-0.7c0.3,0.2,0.5,0.4,0.7,0.7c0.6,1.4,2.1,4.9,4.9,6.1c2.9,1,6,1,8.8,0c2-1,3.4-2.9,3.7-5.2
	c0.2-1.3,0.3-2.7,0.3-4c0,0,3.5-1.9,4.2-2.2c0.6-0.1,1.3-0.1,1.9,0.1C123,121.5,123,119.4,121.4,119.3z M92.8,126
	c-0.7,2.4-2.5,4.3-4.9,5.1c-3.1,0.9-7.9-0.4-8.5-3.8c-0.3-2.1-0.9-5.2,1.5-6c1.7-0.5,3.4-0.7,5.2-0.5c1.8,0.3,3.6,0.9,5.2,1.8
	C92.7,123.4,93.5,124.4,92.8,126z M114.5,127.4c-0.6,3.4-5.4,4.7-8.5,3.8c-2.4-0.8-4.2-2.7-4.9-5.1c-0.6-1.6,0.2-2.5,1.5-3.3
	c1.6-0.9,3.3-1.6,5.2-1.8c1.7-0.2,3.5,0,5.2,0.5C115.5,122.1,114.9,125.3,114.5,127.4L114.5,127.4z"/>
					<path class="st8" d="M250.9,175.3c25.4,0,48.9-13.3,61.9-35c-2.5-8.3-8.5-27.6-11.7-32.2c-4.1-5.9-24.9-17.4-36.2-20.4V86
	c0-0.8-0.4-2.3-1.6-2.3h-25.2c-1.2,0-1.6,1.5-1.6,2.3v1.7c-11.2,3-32,14.5-36.2,20.4c-3.2,4.5-9.1,23.3-11.6,31.8
	C201.8,161.9,225.4,175.3,250.9,175.3z"/>
					<path class="st9" d="M214.1,129.1c-2.3,4.4-5.7,26.3-6.6,31.8c2.2,1.7,4.6,3.2,7,4.6C212.4,152.8,214.1,129.1,214.1,129.1z
	 M293.7,161.3c-0.7-4.6-4.2-27.6-6.6-32.2c0,0,1.8,24.3-0.5,36.8C289,164.5,291.4,163,293.7,161.3z"/>
					<path class="st10" d="M270.6,49.1L270.6,49.1c0.3-3.9,0.4-6.7,0.4-6.7c0.7-16.7-20.5-16.3-20.5-16.3s-21.2-0.4-20.5,16.3
	c0,0,0,2.8,0.3,6.7c-4,0.5-6.8,4.2-6.3,8.2c0.5,3.7,3.6,6.4,7.3,6.4c0.4,0,0.7,0,1.1-0.1c0.8,3.4,1.9,6.4,3.3,8.4
	c1.3,1.8,2.5,3.2,3.6,4.4v8.1c0,2.4,2.8,5.5,11.2,5.5c8.5,0,11.2-3.1,11.2-5.5v-8.4c1-1.1,2.1-2.4,3.3-4.1c1.5-1.9,2.5-5,3.4-8.4
	c0.4,0.1,0.9,0.1,1.3,0.1c4,0,7.3-3.3,7.3-7.3C277.2,52.7,274.3,49.5,270.6,49.1z"/>
					<path class="st11" d="M250.4,80.7c-4.8,0-7.6-0.9-10.7-4c1.3,3.3,5.6,5.7,10.8,5.7c5.4,0,10-2.7,11-6.1
	C258.3,79.8,255.5,80.7,250.4,80.7z M249.5,63.8c1.5,0.6,2.1,0.5,1.5,0.1c-1.1-0.7-2.1-1.5-3.1-2.4c-0.8-0.8-0.5-1.6,0.2-3.1
	c1-2.3,1.7-4.8,2-7.3c0.1-1.5-0.3-3-1.1-4.2c-1.1-1.3-0.2,1.4-0.5,4.2c-0.3,2.5-1.1,4.8-2.3,7c-0.6,1.3-1.5,3-0.5,3.9
	C246.7,63.1,248,63.3,249.5,63.8z M251.8,69.6c2.5,0,4-0.1,4.6-0.7c0.5-0.6,0.7-1.4,0.5-2.1c-0.2-0.5-1.1-0.4-1.2,0.4
	c-0.2,0.8-1.2,1.1-2.5,1.2s-6,0.2-7.1,0.1s-2,0.4-1,0.7C247.3,69.5,249.6,69.7,251.8,69.6z"/>
					<path id="ojos2b" d="M240.5,49.6c-0.9,0-1.5,0.1-1.5,0.3s0.7,0.3,1.5,0.3s1.6-0.1,1.6-0.3S241.3,49.6,240.5,49.6z M259.3,49.6
	c-0.9,0-1.5,0.1-1.5,0.3s0.7,0.3,1.5,0.3s1.5-0.1,1.5-0.3S260.2,49.6,259.3,49.6z"/>
					<path id="ojos2_2_" d="M240.5,47.1c-0.9,0-1.5,1.2-1.5,2.8s0.7,2.8,1.5,2.8s1.6-1.2,1.6-2.8S241.3,47.1,240.5,47.1z M259.3,47.1
	c-0.9,0-1.5,1.2-1.5,2.8s0.7,2.8,1.5,2.8s1.5-1.2,1.5-2.8S260.2,47.1,259.3,47.1z"/>
					<path class="st12" d="M279.8,36.6c0,0,2.1-8.5-2.8-11.9c-4.8-3.4-7.5-1.1-10.7-6.2c-3.1-5-10-6.6-16.1-5.6s-7.8,4-11.3,3
	s-6.8-2.7-9.1-0.1s-1,5.4-3.5,6.2c-2.5,0.7-8.9,2.3-9.3,8.9c-0.4,6.5,2.6,7.8,3.3,9.3S220,43,217.9,45c-2.1,2-4.6,9.2-0.4,14.8
	c4.2,5.6,13.2,7.6,15.7,7.4l-1.2-4.4c0,0-3.8-0.8-5-3.8c-1-2.3-1-6.7,1.6-7.8c0,0,1,7.5,2.9,8.8c0,0-1.3-10.2-1.4-12.1
	s1.4-3,3.4-3.6s7.4-2.8,8.2-6.6c0,0,13.1,9.1,21.7-2.5c0,2.3,0.9,4.6,2.3,6.4c2.4,2.8,4.7,4.3,4.7,9l-1.2,9.3c0,0,2.7-6.3,3.1-9.6
	c0,0,3.3,1.3,2.4,6.2s-6.2,6.5-6.2,6.5l-0.9,3.2c0,0,13.1,1.3,17-6.7S285.8,41.4,279.8,36.6z M243.6,42.9L243.6,42.9
	c-2.1-1-4.5-0.7-6.3,0.6c-0.6,0.4-0.7,1.1-0.3,1.7s1.1,0.7,1.7,0.3c1.1-0.8,2.6-1,3.9-0.5c0.6,0.3,1.3,0.1,1.6-0.5
	S244.2,43.2,243.6,42.9z M256.2,42.9c-0.6,0.3-0.7,1.1-0.4,1.6c0.3,0.5,1,0.7,1.5,0.5c1.3-0.6,2.8-0.4,3.9,0.4
	c0.2,0.1,0.4,0.2,0.7,0.2c0.4,0,0.8-0.2,1-0.5c0.4-0.5,0.2-1.3-0.3-1.6C260.7,42.2,258.3,41.9,256.2,42.9z"/>
					<path class="st7" d="M367.5,256.5c22.1,12.6,49.2,12.6,71.4,0.1l-21.4-79.4H388L367.5,256.5z"/>
					<path class="st13" d="M420.7,253.6c-3.8,0-10-2.2-12.9-3.3c-3.3-1.3-7-1.3-10.3,0c-2.9,1.2-8.9,3.3-12.7,3.3c-8.8,0-14-4.9-15.1-10
	c-0.1,0.5-0.2,1-0.2,1.5c0,5.7,8,10.4,17.8,10.4c3.5,0,6.9-0.6,10.1-1.9c3.3-1.3,7-1.3,10.3,0c3.2,1.3,6.7,1.9,10.1,1.9
	c9.8,0,17.8-4.6,17.8-10.4c0-0.5-0.1-1-0.2-1.5C434.6,248.7,429.4,253.6,420.7,253.6z"/>
					<path class="st14" d="M454.1,195.6c-2.3-9.3-4-11-6.3-12.3c-5.9-3-12.2-4.9-18.8-5.6c-8.8-0.8-11.6-0.6-11.6-0.6
	c4.1,14.1,3.9,74.5,3.8,86.6c16.7-4.3,31.3-14.4,41.2-28.6C461.4,222.9,456.3,204.6,454.1,195.6z M376.3,177.7
	c-6.6,0.7-12.9,2.6-18.8,5.6c-2.3,1.3-4,3-6.3,12.3c-2.1,8.7-6.9,25.9-8.2,38.2c9.7,14.5,24.3,25.1,41.1,29.7
	c-0.1-12.8-0.2-72.2,3.8-86.3C388,177.2,385.2,177,376.3,177.7L376.3,177.7z"/>
					<path class="st15" d="M429,202.4c2.4-3,4.9-5.8,7.6-8.5c0.2-0.3,0.2-0.7,0-0.9l-7-8.3h-1.1l6,8.3c0.2,0.2,0.2,0.5,0,0.7
	c-1.1,1.2-4.7,4.9-6.6,7.2c-2.6,3.1-1,12.5,1.3,29.1c2,14,4.8,25.5,5.6,28.7c0.6-0.3,1.3-0.6,1.9-1c-1.2-5.4-5.3-24.6-6.2-32.4
	C429.3,216.3,427.5,204.7,429,202.4z M370.9,193.7c-0.2-0.2-0.2-0.5,0-0.7l6-8.3h-1.1l-7,8.3c-0.2,0.3-0.2,0.7,0,0.9
	c2.7,2.7,5.2,5.5,7.6,8.5c1.5,2.3-0.3,13.8-1.4,23c-1,7.6-4.8,25.8-6.1,31.8c0.6,0.4,1.3,0.7,1.9,1c0.9-3.8,3.6-14.8,5.5-28.2
	c2.4-16.6,3.9-26,1.3-29.1C375.6,198.6,372,194.9,370.9,193.7L370.9,193.7z"/>
					<path class="st16" d="M437,216.6l3.7,38.9c1.3-0.8,2.6-1.6,3.8-2.5L437,216.6z M364.8,254.9l3.6-38.3l-7.3,35.7
	C362.2,253.3,363.5,254.1,364.8,254.9z"/>
					<path class="st17" d="M374.2,127.9c-4.6,9.8-10.5,19.6-12.7,26.2s-2,12.7,2.7,17.2c0,0-1.1,14.3,11,16.2s17.6-8.6,17.6-8.6h19.7
	c0,0,3.3,8.1,15.6,8.4c10.2,0.3,14.5-9.9,15.3-14.9s0.3-7.1-0.4-9.4c-0.6-2.2,0-4.6,1.4-6.3c2.1-3,1.1-8.1-3.7-13.3
	s-8.4-9.2-12.2-20.3s-5.4-16.6-14.9-17.9c0,0-7.6-5.2-12.9-4.9s-7.3,3-10.6,3.6s-8.6,4.7-10.4,10S376,124,374.2,127.9z"/>
					<path class="st18" d="M420.2,153c2.2-6.4,3.3-15.5,3.3-23.9c-2.9-2.5-5.2-5.6-6.8-9c-1.2-2.8-2-4.7-2.5-5.8
	c-0.3-0.6-0.9-0.8-1.5-0.6c-0.1,0.1-0.2,0.1-0.3,0.2c-1.7,1.7-5.9,4.8-15,8.6c-7.9,3.3-12.6,5.4-15.1,6.6c0,8.4,1.2,17.5,3.4,23.9
	c1.5,4.4,3.5,7.3,5.8,9.4v11.5c0,4.7-0.2,7.7-2.8,10.1c0,0,4.4,13,14.4,13s14.4-13,14.4-13c-2.7-2.2-2.8-5.4-2.8-10.1v-11.5
	C416.5,160.4,418.6,157.4,420.2,153z M396.6,128.5c-1-0.3-2.1-0.5-3.2-0.7c-1,0-2.1,0.2-3,0.6c-1,0.5-1.6,0.4-1.2-0.2
	c1.1-1.1,2.7-1.7,4.2-1.8c1.2,0.1,2.3,0.5,3.2,1.1C397.7,128.2,397.3,128.6,396.6,128.5z M412.7,127.8c-1.1,0.1-2.1,0.3-3.2,0.7
	c-0.8,0.1-1.1-0.3-0.1-0.9c1-0.6,2.1-1,3.2-1.1c1.6,0,3.1,0.6,4.2,1.8l0,0c0.4,0.6-0.2,0.7-1.1,0.2
	C414.8,128.1,413.8,127.9,412.7,127.8z M376.2,138.2c0,4.4,2.8,8,6.3,8c-1.8-3.7-2.9-9.1-2.9-15.2
	C377.3,132.7,376.1,135.4,376.2,138.2z M425.7,131.1c0,6.1-1.2,11.5-2.9,15.2c3.5,0,6.3-3.6,6.3-8
	C429.2,135.5,427.9,132.8,425.7,131.1z"/>
					<path id="boca_1_" class="st19" d="M396.2,152.8c0.9,2.2,3.9,3.8,7.4,3.8s6.5-1.6,7.4-3.8H396.2z"/>
					<path class="st11" d="M402.6,166.5c-3.1,0-7.3-0.6-11-3.7c1.3,3.3,5.8,5.7,11.1,5.7c5.4,0,9.9-2.4,11.1-5.8
	C410.1,165.9,405.8,166.5,402.6,166.5z M398.6,145.6c0.9,0.5,1.7,0.7,3.8,1.6c1.9,0.8,2.7,1.5,2.3,0.5c-0.2-0.7-1.7-1.8-2.9-2.4
	c-1.6-0.8-1.8-0.6-1-2.3c0.6-1.3,2.4-5.5,2.3-8.5c-0.1-2.3-0.7-4.1-1.3-4.3c-0.6-0.2,0.3,2.3-0.2,5.3c-0.5,2.5-1.4,4.8-2.9,6.9
	C398,144,397.8,145.1,398.6,145.6z"/>
					<path id="ojos3b" d="M393.2,132.7c-0.9,0-1.7,0.2-1.7,0.5c0,0.3,0.8,0.5,1.7,0.5s1.7-0.2,1.7-0.5
	C394.9,132.9,394.1,132.7,393.2,132.7z M412.9,132.7c-0.9,0-1.7,0.2-1.7,0.5c0,0.3,0.8,0.5,1.7,0.5s1.7-0.2,1.7-0.5
	C414.6,132.9,413.8,132.7,412.9,132.7L412.9,132.7z"/>
					<path id="ojos3_2_" d="M393.2,130.3c-0.9,0-1.7,1.3-1.7,2.9s0.8,2.9,1.7,2.9s1.7-1.3,1.7-2.9S394.1,130.3,393.2,130.3z M412.9,130.3
	c-0.9,0-1.7,1.3-1.7,2.9s0.8,2.9,1.7,2.9s1.7-1.3,1.7-2.9S413.8,130.3,412.9,130.3L412.9,130.3z"/>
					<path class="st20" d="M53.2,124.4h6c1.7,0,3.2-1.4,3.2-3.2V84.4c0-1.7-1.4-3.2-3.2-3.2h-56c-1.7,0-3.2,1.4-3.2,3.2v36.8
	c0,1.7,1.4,3.2,3.2,3.2h37.6L65.5,146c0.1,0.1,0.3,0.1,0.4,0s0.1-0.3,0-0.4L53.2,124.4z M7.9,90.5H54c0.6,0,1,0.4,1,1s-0.4,1-1,1
	H7.9c-0.6,0-1-0.4-1-1S7.3,90.5,7.9,90.5z M7.9,97.9H54c0.6,0,1,0.4,1,1s-0.4,1-1,1H7.9c-0.6,0-1-0.4-1-1S7.3,97.9,7.9,97.9z
	 M34.6,114.7H7.9c-0.6,0-1-0.4-1-1s0.4-1,1-1h26.7c0.6,0,1,0.4,1,1S35.1,114.7,34.6,114.7z M7.9,107.3c-0.6,0-1-0.4-1-1s0.4-1,1-1
	H54c0.6,0,1,0.4,1,1s-0.4,1-1,1H7.9z M193.6,43.1h6c1.7,0,3.2-1.4,3.2-3.1V3.2c0-1.7-1.4-3.1-3.1-3.2h-56.2c-1.7,0-3.2,1.4-3.2,3.2
	V40c0,1.7,1.4,3.2,3.2,3.2h37.6l24.8,21.5c0.1,0.1,0.3,0.1,0.4,0c0.1-0.1,0.1-0.2,0-0.3L193.6,43.1z M148.3,9.2h46.2
	c0.6,0,1,0.4,1,1s-0.4,1-1,1h-46.2c-0.6,0-1-0.4-1-1S147.7,9.2,148.3,9.2z M148.3,16.6h46.2c0.6,0,1,0.4,1,1s-0.4,1-1,1h-46.2
	c-0.6,0-1-0.4-1-1S147.7,16.6,148.3,16.6z M175,33.4h-26.7c-0.6,0-1-0.4-1-1s0.4-1,1-1H175c0.6,0,1,0.4,1,1
	C176,33,175.5,33.4,175,33.4z M148.3,26c-0.6,0-1-0.4-1-1s0.4-1,1-1h46.2c0.6,0,1,0.4,1,1s-0.4,1-1,1H148.3z M499.9,90.8h-56.1
	c-1.7,0-3.2,1.4-3.2,3.2v36.8c0,1.7,1.4,3.1,3.2,3.1h6l-12.7,21.3c-0.1,0.1,0,0.2,0,0.3c0.1,0.1,0.3,0.1,0.4,0l24.8-21.6H500
	c1.7,0,3.2-1.4,3.2-3.2V93.9C503,92.2,501.6,90.8,499.9,90.8z M475.4,124.2h-26.7c-0.6,0-1-0.4-1-1s0.4-1,1-1h26.7c0.6,0,1,0.4,1,1
	S475.9,124.2,475.4,124.2z M494.8,116.8h-46.2c-0.6,0-1-0.4-1-1s0.4-1,1-1h46.2c0.6,0,1,0.4,1,1S495.4,116.8,494.8,116.8z
	 M494.8,109.4h-46.2c-0.6,0-1-0.4-1-1s0.4-1,1-1h46.2c0.6,0,1,0.4,1,1S495.4,109.4,494.8,109.4z M494.8,102h-46.2c-0.6,0-1-0.4-1-1
	s0.4-1,1-1h46.2c0.6,0,1,0.4,1,1S495.4,102,494.8,102z"/>
				</svg>
            </div>
        );
    }
}

export default Personal;