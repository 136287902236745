import React, {Component} from 'react';
import "assets/scss/styles.scss";


class Transporte extends Component {
    render() {
        return (
            <div>
                <svg version="1.1" id="Transporte" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 503.3 335.2">

					<rect id="cielo" class="st0" width="503.3" height="235.9"/>
					<rect id="asfalto" x="0.2" y="235.9" class="st1" width="503.1" height="99.4"/>
					<rect id="lineaCarril" x="0.2" y="274.1" class="st2" width="503.1" height="2"/>
					<path id="lineasPuntos" class="st2" d="M0.5,293.5h5.4v-2H0.5V293.5z M122.2,293.5h10.6v-2h-10.6V293.5z M79.9,293.5h10.6v-2H79.9
	L79.9,293.5z M249.2,293.5h10.6v-2h-10.6V293.5z M58.8,293.5h10.6v-2H58.8L58.8,293.5z M270.4,293.5H281v-2h-10.6L270.4,293.5z
	 M228.1,293.5h10.6v-2h-10.6L228.1,293.5z M164.6,293.5h10.6v-2h-10.6L164.6,293.5z M143.4,293.5H154v-2h-10.6L143.4,293.5z
	 M101,293.5h10.6v-2h-10.5L101,293.5z M185.7,293.5h10.6v-2h-10.5L185.7,293.5z M206.9,293.5h10.6v-2h-10.5L206.9,293.5z
	 M291.5,293.5h10.6v-2h-10.6L291.5,293.5z M376.1,293.5h10.6v-2h-10.5L376.1,293.5z M333.8,293.5h10.6v-2h-10.5L333.8,293.5z
	 M16.4,293.5H27v-2H16.4L16.4,293.5z M37.6,293.5h10.6v-2H37.6L37.6,293.5z M355,293.5h10.6v-2H355L355,293.5z M312.6,293.5h10.6v-2
	h-10.5L312.6,293.5z M397.3,293.5h5.4v-2h-5.4V293.5z M4.8,257.4H0.5v0.7h4.4L4.8,257.4z M83.5,258.1h8.7v-0.7h-8.6L83.5,258.1z
	 M66,258.1h8.9v-0.7h-8.8L66,258.1z M48.5,258.1h8.8v-0.7h-8.7L48.5,258.1z M127.2,257.4h-8.6v0.7h8.7L127.2,257.4z M109.7,257.4
	H101v0.7h8.7L109.7,257.4z M22.2,257.4h-8.6v0.7h8.7L22.2,257.4z M39.7,257.4h-8.6v0.7h8.7L39.7,257.4z M232.1,257.4h-8.6v0.7h8.7
	L232.1,257.4z M319.5,257.4h-8.6v0.7h8.7L319.5,257.4z M293.3,258.1h8.7v-0.7h-8.6L293.3,258.1z M337,257.4h-8.6v0.7h8.7L337,257.4z
	 M380.8,258.1h8.7v-0.7h-8.6L380.8,258.1z M345.8,258.1h8.9v-0.7h-8.8L345.8,258.1z M275.8,258.1h8.7v-0.7h-8.6L275.8,258.1z
	 M144.7,257.4H136v0.7h8.7L144.7,257.4z M363.3,258.1h8.7v-0.7h-8.6L363.3,258.1z M170.9,258.1h8.7v-0.7H171L170.9,258.1z
	 M153.4,258.1h8.7v-0.7h-8.6L153.4,258.1z M188.4,258.1h8.7v-0.7h-8.6L188.4,258.1z M258.3,258.1h8.8v-0.7h-8.7L258.3,258.1z
	 M214.7,257.4H206v0.7h8.8V257.4z M249.7,257.4H241v0.7h8.7L249.7,257.4z M398.4,257.4v0.7h4.3v-0.7H398.4z"/>
					<path id="nubes" class="st2" d="M156.1,66.7c-1.2-1.2-2.9-1.6-4.5-1.1c-0.2-0.6-0.8-1-1.4-1.1c0,0-0.3-3.7-4.2-2
	c0,0-2.8-4.2-7.7-3.3c0,0-0.8-8.1-10.8-6.4c0,0-1.8-5.8-10.5-4.7s-9.1,8.6-9.1,8.6s-7.9-2.1-10.9,5.6c0,0-1.9-2.2-5.3-0.1
	c0,0-1.9-4.8-8.8-3.8c0,0-1.1-4.4-7.4-3.8c0,0-0.5-5-5-5s-5.1,2-5.1,2c-3.3,0.1-5.9,2.6-6.3,5.8c0,0-4.2-0.6-5.3,2.4
	c0,0-3.9-0.4-4.6,2c0,0-4.7-1.6-6.7,1.7c-1.5,0.1-2.8,1.2-3.1,2.7c0,0-2.7-1.6-4.5,0.7c0,0-1.8-0.6-2.6,0.4c0,0-2.2,0-2.2,1
	s1.8,1.1,4,0.7c1.7,0.6,3.5,0.5,5.1-0.3c0,0,0.9,1.8,7.3,0.6c0,0,2.3,1.5,9.5,0.5c3.7,1.6,7.9,1.7,11.7,0.3
	c3.6,1.6,7.8,1.6,11.5,0.1c0,0,2.9,0.6,4.5-0.1c0,0,2.5,5.1,9.3,2.5c0,0,3.9,2.7,8.9-1.4c0,0,9.1,5.1,17.6-0.1
	c0,0,10.1,4.8,19.8-1.1c0,0,2.2,1.6,5.6-0.3c1.3,0.5,2.8,0.3,3.9-0.6c0,0,1.8,1.3,5.3-0.4c1.5,0.6,3.1,0.5,4.5-0.2
	c0,0,2.9,0.3,2.7-1.1S156.1,66.7,156.1,66.7z M497.4,34.8c-2.9-3.2-7.6-3.8-11.2-1.4c0,0-3.3-4.1-9.7-1.4c-1-0.9-2.4-1.3-3.6-0.9
	c-1.8-2.7-5.2-4-8.4-3.2c0,0-9.1-15.6-24.8-4c0,0-8.7-6.5-16.9,2.8c0,0-6.8-6.1-13,1.5c0,0-7.2-0.6-8.9,4.9c0,0-7.5-0.4-9,5.2
	c-4.1-2.9-9.6-2.5-13.3,0.8c-3-2.2-7.1-2.1-10,0.3c-2.1-1.4-4.7-1.6-6.9-0.5c0,0-5.5-3.7-11.2,3.9c-1.7-0.4-3.4,0.6-3.9,2.2
	c0,0-6.3-0.8-7.9,1.5c0,0-5.8-0.6-6.4,1.5s12.5,0.9,12.5,0.9s3.8,2.2,8.5-0.6c0,0,5.6,4.7,13.1-0.8c0,0,4.4,3.9,15.3,2.7
	c0,0,2.6,3.6,8.4,2.5c0,0,3.3,3.2,14.9-1.1c0,0,1.9,3.8,7,1.6c0,0,3.1,2.6,8.2,0.1c0,0,4.6,3.3,14.2,0c0,0,5.1,3.9,11.8-3.1
	c0,0,2.2,2.1,6.8,0c0,0,0.2,5.8,11.1,1.1c0,0,1.9,2.1,5.9,1.7c0,0,2,2.9,6.5,0.9c0,0,4.5,1.4,6.6-2.9c0,0,7.5,5.7,19.5,1.9
	c0.2,0.2,0.5,0.5,0.8,0.6V32.7C501.1,32.8,499.1,33.5,497.4,34.8z M344.3,83.7c-3.1-0.1-4.9-0.1-4.9-0.1s-0.8-1.6-4.4-0.7
	c0,0-2-2.5-6.6-1.2c-1.3-1.6-3.3-2.5-5.4-2.5c0,0,0.9-5-7.6-5.7c0,0-4.9-7.2-15-4.2c0,0-1.8-1.8-6.8-2.1c0,0-3.4-5.2-13.9-5.5
	c0,0-14.2-7.7-25.7,6.8c0,0-14.9-5.5-17.9,6.2c0,0-3-1.4-5.2,0c0,0-0.4-2.5-4.9-1.5c0,0-7.9-1.3-10.4,5.8c0,0-4.4,0.6-4.9,2.8
	c-2.6-2.2-6.3-2.4-9.1-0.6c0,0-11.1-1.3-13.6,2c0,0-3.8,0.1-3.8,1.4s3.4,1,3.4,1s2,1.6,7.3-0.2c2.1,0.8,4.4,0.8,6.5,0
	c0,0,2,2.4,10,0c0,0,2.9,4.5,10.1,1.1c0,0,2.4,3.3,9.3,2c0,0,6.3,4.1,17.4-0.4c0,0,9.3,4.9,22.2,0.4c8,2.3,16.4,2.2,24.3-0.3
	c0,0,9.8,3.1,24.3-0.7c0,0,3.8,2.1,9.9-0.4c2.1,0.6,4.4,0.4,6.3-0.6c0,0,7.2,0.1,9.6-0.6S347.4,83.8,344.3,83.7z"/>
					<path id="ciudad" class="st3" d="M375.8,213.3v-44.9h-18.1v37.5h-4.6v-46.4h-4.9v-2.9h-11.9v49.3h-3.2v11.2h-1.9v-33.3h-5.6v-5.5
	h-18.7v4.5h-4.4v17H298v-9.6h-6v4h-9.1v19.2h-1.3v-44.9h-18.1v37.5h-4.6v-46.4H254v-2.9h-11.9v49.3h-3.2v11.2H237v-33.3h-5.5v-5.5
	h-18.7v4.5h-4.4v17h-4.7v-9.6h-6v4h-9.1v19.2h-1.3v-44.9h-18.1v37.5h-4.6v-46.4h-4.9v-2.9h-11.9v49.3h-3.2v11.2h-1.9v-33.3h-5.6
	v-5.5h-18.7v4.5h-4.4v17h-4.7v-9.6h-6v4h-9.1v19.2H93v-44.9H74.8v37.5h-4.6v-46.4h-4.9v-2.9H53.5v49.3h-3.2v11.2h-1.9v-33.3h-5.5
	v-5.5H24.2v4.5h-4.4v17h-4.7v-9.6h-6v4H0v54.3h377.1v-35.1H375.8z"/>
					<g id="auto">
						<path id="chasis_2_" class="st4" d="M78.4,222.5c-10.9,0-18.2,0.4-23.4,2.5s-18.9,9.7-22.5,12.2c0,0-13.4,1.1-19.8,3
		S1,246.4,1,252.9c0,6.6-1.1,9.6,4.9,9.6s109.7-1.5,112.6-1.9s6.3-2.6,7.2-3.3s0.8-4.7,0.7-6s-2.6-3-2.6-3v-5.8c0-2.4,0-3.4-4.1-6.6
		s-16-10.7-17.9-11.8S95.1,222.4,78.4,222.5z"/>
						<path id="guardabarro_2_" class="st5" d="M25.8,246.7c-6.8,0-12.4,5.3-12.4,11.8c0,1.3,0.2,2.6,0.7,3.9c5.8-0.1,14.1-0.2,23.5-0.3
		c0.4-1.1,0.6-2.3,0.6-3.5C38,251.9,32.5,246.6,25.8,246.7z M105.8,246.7c-6.7-0.2-12.2,5.1-12.4,11.8c0,0.9,0.1,1.8,0.3,2.6
		c12.4-0.2,21.8-0.4,24.2-0.6c0.1-0.7,0.2-1.4,0.2-2.1C118.2,252,112.7,246.7,105.8,246.7z"/>
						<path class="st2" d="M13.1,243.6c-3,0.4-7.3,1.1-8.2,1.8s-2.2,2.8-2,3.2s1,0.5,3.2,0.5s4.2,0.1,5.6-1.1c1.1-1.1,2.1-2.3,3-3.5
		C15,244,14.7,243.5,13.1,243.6z"/>
						<path id="ruedas_2_" class="st6" d="M25.8,248.3c-5.7,0-10.2,4.6-10.2,10.2c0,5.7,4.6,10.2,10.2,10.2c5.7,0,10.2-4.6,10.2-10.2
		C36,252.9,31.4,248.3,25.8,248.3z M105.8,248.3c-5.7,0-10.2,4.6-10.2,10.2c0,5.7,4.6,10.2,10.2,10.2c5.7,0,10.2-4.6,10.2-10.2
		C116.1,252.9,111.5,248.3,105.8,248.3z M6,256.1c-0.1-0.2-0.4-0.3-0.6-0.3H1c-0.1,1.3,0,2.6,0.2,3.8h6c0.4,0,0.8-0.3,0.8-0.8
		c0-0.2,0-0.3-0.1-0.4L6,256.1z"/>
						<path id="llantas_2_" class="st7" d="M25.8,251.7c-3.8,0-6.8,3-6.8,6.8c0,3.8,3,6.8,6.8,6.8c3.8,0,6.8-3,6.8-6.8
		C32.6,254.8,29.5,251.7,25.8,251.7z M105.8,251.7c-3.8,0-6.8,3-6.8,6.8c0,3.8,3,6.8,6.8,6.8c3.8,0,6.8-3,6.8-6.8
		C112.6,254.8,109.6,251.7,105.8,251.7z"/>
						<path id="vidrios_2_" class="st8" d="M52.2,226.2c-6.7,3.3-13.3,6.9-19.6,10.9c0,0,4.5,0.1,6-0.8s10.7-7.4,13-8.6
		C53.4,226.6,54.3,226.1,52.2,226.2z M119.8,235.8c-2.6-2.1-8.2-5.8-12.6-8.5c-0.6,0.1-1.2,0.3-1.6,0.7c-0.5,0.6,1.7,1.7,3.9,3.7
		c1.6,1.5,3.2,2.8,5,4.1C116.3,236,118,236,119.8,235.8L119.8,235.8z M101.5,231.4c-2.8-2.5-4.5-5-9.4-5.3
		c-3.6-0.2-9.5-0.2-15.5-0.1c-0.1,2.6-0.4,7.3-0.3,10.5H79c10.6-0.1,23.9-0.6,25.1-0.6S104.4,234,101.5,231.4L101.5,231.4z
		 M60.6,227.3c-4.3,1.3-8.4,3.2-12.2,5.6c-5,3.2-6.7,4.7-4,4.6c2.1,0,16.1-0.6,27-0.9c0.1-2.1,0.8-7.5,1.3-10.5l-2.8,0.1
		C66.9,226.2,63.7,226.6,60.6,227.3L60.6,227.3z"/>
						<path id="lucesAtras_2_" class="st9" d="M118.4,240.9c-0.8,0-0.8,0.8,0,2.1s2.8,4.4,3.4,4.8c0.6,0.3,1.3,0.4,2,0.5v-5.8
		c0-0.5,0-1.1-0.1-1.6H118.4z M126.6,252.1H125c-0.5,0-1,0.3-1,0.8c0,0,0,0.1,0,0.1c0,0.6,0.5,0.9,1.2,0.9h1.4
		C126.6,253.3,126.6,252.7,126.6,252.1z"/>
					</g>
					<g id="colectivo">
						<rect id="FondoRuedas" x="199" y="239" class="st10" width="197.4" height="16"/>
						<path id="Gomas" class="st11" d="M378.1,240.1c-7.6,0-13.8,6.2-13.8,13.8s6.2,13.8,13.8,13.8s13.8-6.2,13.8-13.8l0,0
		C391.9,246.3,385.8,240.1,378.1,240.1z M217.1,240.1c-7.6,0-13.8,6.2-13.8,13.8s6.2,13.8,13.8,13.8s13.8-6.2,13.8-13.8l0,0
		C230.9,246.3,224.8,240.1,217.1,240.1z"/>
						<path id="Llantas" class="st12" d="M378.1,243.3c-5.9,0-10.6,4.7-10.6,10.6c0,5.9,4.7,10.6,10.6,10.6s10.6-4.7,10.6-10.6
		C388.7,248,384,243.3,378.1,243.3z M217.1,243.3c-5.9,0-10.6,4.7-10.6,10.6c0,5.9,4.7,10.6,10.6,10.6s10.6-4.7,10.6-10.6
		C227.7,248,223,243.3,217.1,243.3z"/>
						<path id="Guardabarros" class="st13" d="M395.5,258.4h-2.1c-0.1,2.9,0.1,5.9,0.5,8.8c0.1,0.1,2.7-2,2.7-2
		C395.9,263,395.5,260.7,395.5,258.4z M234.4,258.4h-2.1c-0.1,2.9,0.1,5.9,0.5,8.8c0.1,0.1,2.7-2,2.7-2
		C234.8,263,234.4,260.7,234.4,258.4L234.4,258.4z M217.1,250.8c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.1,3.1
		c1.7,0,3.1-1.4,3.1-3.1c0,0,0,0,0,0C220.3,252.2,218.9,250.8,217.1,250.8z M378.1,250.8c-1.7,0-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1
		s3.1-1.4,3.1-3.1c0,0,0,0,0,0C381.3,252.2,379.9,250.8,378.1,250.8z M378.1,246.1c-4.3,0-7.8,3.5-7.8,7.8c0,4.3,3.5,7.8,7.8,7.8
		c4.3,0,7.8-3.5,7.8-7.8C385.9,249.6,382.4,246.1,378.1,246.1z M378.1,259.1c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2
		c2.9,0,5.2,2.3,5.2,5.2l0,0C383.3,256.8,381,259.1,378.1,259.1L378.1,259.1z M217.1,246.1c-4.3,0-7.8,3.5-7.8,7.8s3.5,7.8,7.8,7.8
		c4.3,0,7.8-3.5,7.8-7.8c0,0,0,0,0,0C224.9,249.6,221.4,246.2,217.1,246.1L217.1,246.1z M217.1,259.1c-2.9,0-5.2-2.3-5.2-5.2
		s2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2C222.3,256.8,220,259.1,217.1,259.1L217.1,259.1z"/>
						<path id="BaseColectivo" class="st2" d="M152.1,255.9c-0.6-1-0.5-3.8-0.4-4.9s0.1-1.9,0.3-3.8s0.6-5.3,1.9-7.7
		c1.6-2.9,2.7-4.3,3.2-8.4s1.5-12.3,1.5-16.6s1.2-22.4,1.3-24.6c0-1.8,0.4-3.6,1.4-5.1c1.4-2,9.2-1.8,9.2-1.8h285.1
		c0.5,0,1,0.2,1.5,0.4l0.3,0.2c1.5,1.1,2,3.2,2.3,5.4c0.2,1.7,1,56.9,1,57.8c0,1.1,0.5,2.1,1.3,2.8c0.6,0.5,0.8,1.7,0.8,3
		c0,0.8,0,1.5-0.2,2.3c-0.3,1.9-0.6,3.2-4.2,3.3l-1.9,0c-1.5,0-62.9,0-62.9,0v-3.8c0.2-8.4-6.5-15.5-14.9-15.7
		c-8.4-0.2-15.5,6.5-15.7,14.9c0,0.3,0,0.5,0,0.8v3.8H232.3v-3.8c0.2-8.4-6.5-15.5-14.9-15.7c-8.4-0.2-15.5,6.5-15.7,14.9
		c0,0.3,0,0.5,0,0.8v3.8h-31.4c0,0-5,0-9.6-0.2c-2.2,0-4.4-0.2-6.5-0.7C153.4,257.3,152.6,256.7,152.1,255.9z"/>
						<path id="ColorVerde" class="st14" d="M461.9,249.7c-0.8-0.7-1.3-1.7-1.3-2.8c0-0.1-0.1-7.5-0.1-9.2H176c-2.3,0-4.6,0.5-6.8,1.4
		c-2.9,1.2-8.3,2.7-16.1,2.3c-0.6,1.9-1,3.9-1.1,5.9c-0.2,1.9-0.2,2.7-0.3,3.8s-0.2,3.8,0.4,4.9c0.5,0.8,1.2,1.4,2.2,1.7
		c2.1,0.4,4.3,0.7,6.5,0.7c4.5,0.1,9.6,0.2,9.6,0.2h31.4v-3.8c0-4.1,1.7-8.1,4.6-10.9l1.6-1.3c5.4-4,12.8-4,18.2,0l1.6,1.3
		c2.9,2.9,4.6,6.8,4.6,10.9v3.8h130.5v-3.8c0-4.1,1.7-8.1,4.6-10.9l1.6-1.3c5.4-4,12.8-4,18.2,0l1.6,1.3c2.9,2.9,4.6,6.8,4.6,10.9
		v3.8c0,0,61.4,0.1,62.9,0l1.9,0c3.6-0.1,3.9-1.4,4.2-3.3c0.1-0.8,0.2-1.5,0.2-2.3C462.7,251.4,462.5,250.2,461.9,249.7z"/>
						<path id="luzAmarillo" class="st15" d="M458.4,222.4c0,1.3,0.8,2.4,2,2.8l-0.1-5.5C459.1,220.1,458.4,221.2,458.4,222.4z
		 M163.7,246.7v1.6c0,0.1,0.1,0.2,0.2,0.2h3.1c0.1,0,0.2-0.1,0.2-0.2v-1.6c0-0.1-0.1-0.2-0.2-0.2H164
		C163.8,246.5,163.7,246.6,163.7,246.7z M443.3,246.8v1.4c0,0.2,0.2,0.4,0.4,0.4l0,0h2.8c0.2,0,0.4-0.2,0.4-0.3v0v-1.4
		c0-0.2-0.2-0.3-0.4-0.3h-2.8C443.4,246.5,443.3,246.6,443.3,246.8z"/>
						<path id="luzRoja" class="st16" d="M458.5,228.7c0,1.2,0.8,2.3,1.9,2.8l-0.1-5.5C459.2,226.4,458.5,227.5,458.5,228.7z
		 M459.1,234.6c0,0.9,0.6,1.7,1.4,2l-0.1-4C459.6,233,459.1,233.7,459.1,234.6z M163.7,251.2v1.6c0,0.1,0.1,0.2,0.2,0.2h3.1
		c0.1,0,0.2-0.1,0.2-0.2v-1.6c0-0.1-0.1-0.2-0.2-0.2H164C163.8,251,163.7,251.1,163.7,251.2z M443.3,251.3v1.4
		c0,0.2,0.2,0.3,0.4,0.3h2.8c0.2,0,0.3-0.2,0.4-0.3v-1.4c0-0.2-0.2-0.4-0.4-0.4l0,0h-2.8C443.4,251,443.3,251.1,443.3,251.3
		L443.3,251.3z"/>
						<path id="Vidrios" class="st17" d="M446.2,191.5h-14.1c-0.1,0-0.3,0.2-0.3,0.5v28.2c0,0.3,0.2,0.5,0.3,0.5c4.1,0,14.4-14,14.4-16.1
		v-12.6C446.6,191.8,446.4,191.5,446.2,191.5z M427.9,191.5H408c-0.4,0-0.7,0.2-0.7,0.5v28.2c0,0.3,0.3,0.5,0.7,0.5h19.9
		c0.4,0,0.7-0.2,0.7-0.5v-28.2C428.7,191.8,428.3,191.5,427.9,191.5L427.9,191.5z M403.9,191.5h-31.1c-0.1,0-0.3,0.2-0.3,0.5v28.2
		c0,0.3,0.2,0.5,0.3,0.5h31.1c0.2,0,0.3-0.2,0.3-0.5v-28.2C404.2,191.8,404,191.5,403.9,191.5L403.9,191.5z M369,191.5h-31.1
		c-0.2,0-0.3,0.2-0.3,0.5v28.2c0,0.3,0.2,0.5,0.3,0.5H369c0.2,0,0.3-0.2,0.3-0.5v-28.2C369.3,191.8,369.1,191.5,369,191.5z
		 M334,191.5h-31.1c-0.2,0-0.3,0.2-0.3,0.5v28.2c0,0.3,0,0.5,0.2,0.5h31.1c0.3-0.1,0.5-0.3,0.5-0.5v-28.2
		C334.4,191.8,334.2,191.5,334,191.5z M299.1,191.5H268c-0.1,0-0.3,0.2-0.3,0.5v28.2c0,0.3,0.2,0.5,0.3,0.5h31.1
		c0.2,0,0.3-0.2,0.3-0.5v-28.2C299.5,191.8,299.3,191.5,299.1,191.5L299.1,191.5z M264.2,191.5h-31.1c-0.2,0-0.3,0.2-0.3,0.5v28.2
		c0,0.3,0.2,0.5,0.3,0.5h31.1c0.2,0,0.3-0.2,0.3-0.5v-28.2C264.5,191.8,264.4,191.5,264.2,191.5z M229.3,191.5h-31.1
		c-0.2,0-0.3,0.2-0.3,0.5v28.2c0,0.3,0.2,0.5,0.3,0.5h31.1c0.2,0,0.3-0.2,0.3-0.5v-28.2C229.6,191.8,229.4,191.5,229.3,191.5z
		 M194.6,191.5l-20.4-0.1c-0.1,0.2-0.2,0.5-0.2,0.8v32.5c0,0.4,0.1,0.7,0,0.7h13.8c4.8,0,6.9-2.4,6.9-8v-25.2
		C194.6,191.8,194.5,191.6,194.6,191.5z M166.1,194.6c-0.7,0-1.6,0.6-1.9,2.4c-0.4,2.4-1.7,15.4-2.6,24.1c-0.3,3.4-0.6,6.1-0.7,7.4
		c-0.1,0.6-0.1,1.2-0.2,1.8c-0.4,3.1-0.8,6.3,0.2,7.5c0.3,0.3,0.7,0.5,1.1,0.5c3.2,0,5.6-0.2,5.6-3.1v-38.2
		C167.7,195.3,166.9,194.6,166.1,194.6z M159.8,189.1L159.8,189.1c-0.2,0-0.4,0.2-0.4,0.4c-0.1,0.4-6,38.4-6,50.1
		c0,0.2,0.1,0.4,0.3,0.4h0.1c0.2,0,0.3-0.1,0.4-0.2l0.6-1.1c1.5-2.2,2.4-4.8,2.7-7.5c0.4-3.5,1.5-12.2,1.5-16.6
		c0-3.2,0.7-14.7,1-20.9c0.1-1.8,0.2-3.1,0.2-3.6v-0.6C160.2,189.3,160.1,189.2,159.8,189.1L159.8,189.1z"/>
						<path id="marcos" class="st18" d="M408,221.5h19.9c0.7,0,1.3-0.5,1.4-1.2v-28.2c-0.1-0.7-0.7-1.2-1.4-1.2H408
		c-0.7-0.1-1.3,0.5-1.4,1.2v28.2C406.7,221,407.3,221.5,408,221.5z M418.4,192.2h9.5h0.1v21.9h-9.6V192.2z M407.9,192.2h0.1h9.5
		v21.9h-9.6V192.2z M407.9,215H428v5.2h-0.1H408h-0.1L407.9,215z M302.7,221.4h31.1c0.6,0,1.1-0.5,1.1-1.2v-28.2
		c0.1-0.6-0.4-1.1-1-1.2h-31.1c-0.6,0-1,0.5-1,1.1c0,0,0,0,0,0v28.2C301.9,220.9,302.1,221.5,302.7,221.4L302.7,221.4z M333.7,220.2
		h-30.6V215h30.5L333.7,220.2z M318.9,192.1h14.8v22h-14.8L318.9,192.1z M318,192.1v22h-14.8v-21.9L318,192.1z M194.6,190.9H174
		c-0.3,0-0.6,0.6-0.6,1.3v32.6c0,0.7,0.3,1.3,0.6,1.3h13.8c5.9,0,7.5-3.5,7.5-8.6v-25.3C195.2,191.5,194.9,190.9,194.6,190.9
		L194.6,190.9z M174.6,192.2c0,0,0-0.1,0-0.1h9.2v2.1h-9.2L174.6,192.2z M194.1,217.5c0,5.3-1.8,7.4-6.2,7.4h-13.2c0,0,0-0.1,0-0.1
		v-29.6H194L194.1,217.5z M194.1,194.2h-9.3v-2.1h9.3V194.2z M446.3,190.9h-14.1c-0.6,0-1,0.5-1,1.1c0,0,0,0,0,0v28.2
		c-0.1,0.6,0.4,1.1,1,1.2c0,0,0,0,0,0c4.3,0,15-14.2,15-16.8v-12.6C447.2,191.5,446.8,191,446.3,190.9L446.3,190.9z M432.5,192.1
		h6.3V205h-6.3V192.1z M432.5,220.1v-14.3h13.1C443.6,209.8,435.6,219.6,432.5,220.1L432.5,220.1z M445.9,205h-6.2v-12.8h6.3v12.5
		C445.9,204.8,445.9,204.9,445.9,205z M198.1,221.5h31.1c0.6-0.1,1-0.6,0.9-1.2v-28.2c0.1-0.6-0.4-1.1-0.9-1.2h-31.1
		c-0.6,0-1,0.5-1,1.1c0,0,0,0,0,0v28.2C197,220.9,197.5,221.4,198.1,221.5C198.1,221.5,198.1,221.5,198.1,221.5z M228.9,220.2h-30.5
		V215h30.5L228.9,220.2z M214.1,192.2h14.8v21.9h-14.8V192.2z M213.2,192.2v21.9h-14.8v-21.9H213.2z M268,221.5h31.1
		c0.6-0.1,1-0.6,1-1.2v-28.2c0.1-0.6-0.4-1.1-1-1.2h-31.2c-0.6,0-1,0.5-1,1.1c0,0,0,0,0,0v28.2C266.9,220.9,267.3,221.4,268,221.5
		C267.9,221.5,267.9,221.5,268,221.5L268,221.5z M298.8,220.2h-30.6V215h30.5L298.8,220.2z M284,192.2h14.8v21.9H284L284,192.2z
		 M283.1,192.2v21.9h-14.8v-21.9L283.1,192.2z M166.1,194.2c-0.9,0-2,0.7-2.4,2.7c-0.4,2.4-1.7,15.5-2.6,24.1
		c-0.3,3.4-0.6,6.1-0.7,7.3c-0.1,0.6-0.1,1.2-0.2,1.8c-0.5,3.9-0.8,6.6,0.3,7.8c0.4,0.4,0.9,0.6,1.4,0.6c2.6,0,6,0,6-3.6v-38.2
		C168.1,195,167.1,194.2,166.1,194.2z M167.2,235c0,2.4-1.9,2.7-5.2,2.7c-2.2,0-1.1-5.5-0.7-9.3c0.5-4.5,2.7-28.1,3.3-31.4
		c0.2-1.3,0.9-2,1.5-2s1.1,0.6,1.1,1.8L167.2,235z M404.8,220.3v-28.2c0.1-0.6-0.4-1.1-1-1.2h-31.1c-0.6,0-1,0.5-1,1.1c0,0,0,0,0,0
		v28.2c-0.1,0.6,0.4,1.1,1,1.2c0,0,0,0,0,0h31.1C404.4,221.4,404.8,220.9,404.8,220.3z M388.7,192.2h14.8v21.9h-14.8V192.2z
		 M373,192.2h14.8v21.9H373V192.2z M373,220.2V215h30.5v5.2L373,220.2z M368.9,190.9h-31.1c-0.6,0.1-1,0.6-1,1.2v28.2
		c-0.1,0.6,0.4,1.1,1,1.2h31.1c0.6-0.1,1-0.6,1-1.2v-28.2C369.9,191.5,369.5,191,368.9,190.9z M353.8,192.2h14.8v21.9h-14.8V192.2z
		 M338.1,192.2h14.8v21.9h-14.8V192.2z M338.1,220.2V215h30.5v5.2L338.1,220.2z M233.1,221.5h31.1c0.6-0.1,1-0.6,1-1.2v-28.2
		c0.1-0.6-0.4-1.1-1-1.2H233c-0.6,0-1,0.5-1,1.1c0,0,0,0,0,0v28.2C232,220.9,232.4,221.4,233.1,221.5
		C233,221.5,233,221.5,233.1,221.5L233.1,221.5z M263.9,220.2h-30.6V215h30.5L263.9,220.2z M249,192.2h14.8v21.9H249V192.2z
		 M248.1,192.2v21.9h-14.8v-21.9H248.1z M161.5,195l0.1,0.3l0,0c0.3,0.6,0.6,1.1,1.1,1.5c0,0,0.1,0,0.1,0h0.1c0.1,0,0.1-0.1,0.1-0.1
		V192c0-0.1,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.5,0.3-0.9,0.8-1.2,1.4l0,0l-0.1,0.6l-1,0.3v-0.5c0.1-1.8,0.2-3.1,0.2-3.7v-0.6
		c0-0.4-0.3-0.8-0.7-0.9c-0.1,0.4-0.1,0.9-0.1,1.3v0.1l0,0v0.4c0,0.1,0,0.2,0,0.2v0.1v0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2
		c0,0.1,0,0.3,0,0.4v0.1c0,0.4-0.1,0.8-0.1,1.2v0.2c0,0.1,0,0.2,0,0.2c0,0.3,0,0.5,0,0.8c-1.4,0.4-3.1,1-4.3,1.4
		c-2.5,0.9-4.1,2-4.2,4.3c0,0.1,0,0.1,0,0.2v7.2c0,2.5,1.6,2.9,2.6,2.9c0.4,0,0.8-0.1,1.1-0.1c0.1,0,0.1-0.1,0.1-0.2v-11.3
		c0-0.1-0.1-0.2-0.2-0.2c-0.8,0-1.6,0.1-2.4,0.4c0.5-1,1.6-1.6,3.3-2.2c1.1-0.4,2.6-0.9,3.9-1.3c0,0.1,0,0.3,0,0.4v0.1
		c0,0.3,0,0.5-0.1,0.8l0,0c0,0.3,0,0.5,0,0.8v0.5c0,0.1,0,0.3,0,0.4v0.4v0.6c0,0.1,0,0.3,0,0.4v0.5c0,0.2,0,0.5,0,0.7
		c0,0.1,0,0.1,0,0.2v0.6v0.5c0,0.1,0,0.2,0,0.4v0.6v0.4c0,0.2,0,0.4,0,0.6s0,0.3,0,0.4v0.5c0,0.1,0,0.3,0,0.4v0.6c0,0.1,0,0.3,0,0.4
		v0.5c0,0.1,0,0.2,0,0.4v0.6c0,0.1,0,0.1,0,0.2c0,0.2,0,0.5,0,0.7s0,0.2,0,0.3s0,0.4,0,0.5s0,0.2,0,0.3s0,0.4,0,0.6v0.1
		c0,0.2,0,0.4,0,0.6s0,0.1,0,0.2s0,0.3,0,0.5v0.1c0,0.2,0,0.4,0,0.5l0,0c0,0.2,0,0.4,0,0.6l0,0c0,0.4,0,0.7,0,0.9
		c0,4.2-1,12.5-1.5,16.6s-1.6,5.4-3.2,8.4c-0.1,0.2-0.2,0.5-0.4,0.8l0.1,0.1h0.2c0.3,0,0.6-0.2,0.8-0.4l0.6-1.1
		c1.5-2.3,2.4-4.9,2.7-7.6c0.4-3.5,1.5-12.2,1.5-16.7c0-3,0.6-12.9,1-19.3L161.5,195z M205.3,240.7h-7.2c-0.4,0-0.7,0.4-0.7,0.9
		c0,0.4,0.3,0.6,0.7,0.7h7.2c0.4,0,0.7-0.4,0.7-0.9C206,241.1,205.7,240.8,205.3,240.7L205.3,240.7z M446,240.7h-55.9
		c-0.4,0-0.7,0.4-0.7,0.9c0,0.4,0.3,0.6,0.7,0.7H446c0.4,0,0.8-0.3,0.9-0.7c0-0.4-0.3-0.8-0.7-0.9
		C446.2,240.7,446.1,240.7,446,240.7L446,240.7z M366.1,240.7H229.5c-0.4,0-0.7,0.4-0.7,0.9c0,0.4,0.3,0.6,0.7,0.7h136.7
		c0.4,0,0.8-0.3,0.9-0.7c0-0.4-0.3-0.8-0.7-0.9C366.3,240.7,366.2,240.7,366.1,240.7L366.1,240.7z"/>
					</g>
					<g id="autoBlanco">
						<path id="chasis_1_" class="st2" d="M136.5,268.9c-12.9,0-21.4,0.5-27.5,2.9s-22.3,11.4-26.4,14.3c0,0-15.7,1.3-23.3,3.5
		s-13.8,7.4-13.8,15.1s-1.3,11.3,5.8,11.3s129.2-1.8,132.6-2.2s7.4-3.1,8.5-3.9s1-5.5,0.8-7.1s-3.1-3.5-3.1-3.5v-6.8
		c0-2.8,0-3.9-4.8-7.8s-18.8-12.5-21.1-13.8S156.1,268.9,136.5,268.9z"/>
						<path id="guardabarro_1_" class="st1" d="M74.6,297.5c-8.1,0-14.6,6.2-14.6,13.9c0,1.6,0.3,3.1,0.8,4.6l27.7-0.4
		c0.4-1.4,0.7-2.8,0.7-4.2C89.2,303.7,82.7,297.5,74.6,297.5z M168.9,297.5c-8.1,0-14.6,6.2-14.6,13.9c0,1.1,0.1,2.1,0.4,3.1
		c14.6-0.3,25.7-0.5,28.6-0.7c0.2-0.8,0.2-1.6,0.2-2.5C183.5,303.7,176.9,297.5,168.9,297.5z"/>
						<path id="luz_1_" class="st2" d="M59.8,293.9c-3.5,0.4-8.6,1.3-9.7,2.1s-2.6,3.3-2.4,3.8s1.2,0.6,3.7,0.6s4.9,0.1,6.5-1.3
		c1.3-1.3,2.5-2.7,3.5-4.2C62,294.2,61.5,293.6,59.8,293.9z"/>
						<path id="ruedas_1_" class="st6" d="M74.6,299.3c-6.7,0-12.1,5.4-12.1,12.1s5.4,12.1,12.1,12.1s12.1-5.4,12.1-12.1
		S81.3,299.3,74.6,299.3z M168.9,299.3c-6.7,0-12.1,5.4-12.1,12.1s5.4,12.1,12.1,12.1s12.1-5.4,12.1-12.1S175.5,299.3,168.9,299.3z
		 M51.4,308.5c-0.2-0.2-0.4-0.4-0.7-0.4h-5.2c-0.1,1.5-0.1,3,0.1,4.5h7c0.5,0,0.9-0.4,0.9-0.9c0-0.2-0.1-0.4-0.2-0.5L51.4,308.5z"/>
						<path id="llantas_1_" class="st7" d="M74.6,303.4c-4.4,0-8,3.6-8,8s3.6,8,8,8c4.4,0,8-3.6,8-8S79,303.4,74.6,303.4z M168.9,303.4
		c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S173.3,303.4,168.9,303.4z"/>
						<path id="vidrios_1_" class="st8" d="M105.7,273.3c-7.9,3.8-15.6,8.1-23.1,12.8c0,0,5.3,0.1,7-1s12.6-8.6,15.3-10.1
		C107.2,273.9,108.2,273.3,105.7,273.3z M185.3,284.7c-3-2.4-9.7-6.8-14.8-10c-0.7,0.1-1.4,0.4-1.9,0.8c-0.6,0.7,2,2,4.6,4.4
		s4.9,4.3,5.9,4.8C179.8,284.9,183.2,284.8,185.3,284.7L185.3,284.7z M163.8,279.5c-3.3-3-5.3-5.9-11-6.2
		c-4.2-0.2-11.2-0.3-18.2-0.2c-0.2,3-0.4,8.6-0.4,12.3h3.2c12.5-0.1,28.2-0.6,29.5-0.7S167.1,282.5,163.8,279.5z M115.7,274.6
		c-5,1.5-9.9,3.7-14.3,6.6c-5.9,3.8-7.8,5.5-4.7,5.4c2.5,0,18.9-0.7,31.8-1c0.1-2.5,1-8.9,1.6-12.4l-3.3,0.1
		C123,273.4,119.3,273.8,115.7,274.6z"/>
						<path id="lucesAtras_1_" class="st9" d="M183.7,290.6c-0.9,0-0.9,0.9,0,2.5s3.3,5.2,4.1,5.6c0.8,0.3,1.6,0.5,2.4,0.6v-6.8
		c0-0.6,0-1.3-0.1-1.9L183.7,290.6z M193.3,303.9h-1.8c-0.6,0-1.1,0.4-1.1,1c0,0,0,0,0,0.1c0,0.7,0.6,1,1.4,1h1.6
		C193.3,305.2,193.3,304.5,193.3,303.9L193.3,303.9z"/>
					</g>
					<g id="autoRojo">
						<path id="chasis" class="st19" d="M433.6,268.9c-12.9,0-21.4,0.5-27.5,2.9s-22.3,11.4-26.4,14.3c0,0-15.7,1.3-23.3,3.5
		s-13.8,7.4-13.8,15.1s-1.3,11.3,5.8,11.3s129.2-1.8,132.6-2.2s7.4-3.1,8.5-3.9s1-5.5,0.8-7.1s-3.1-3.5-3.1-3.5v-6.8
		c0-2.8,0-3.9-4.8-7.8s-18.8-12.5-21.1-13.8S453.3,268.9,433.6,268.9z"/>
						<path id="guardabarro" class="st20" d="M371.7,297.5c-8,0-14.6,6.2-14.6,13.9c0,1.6,0.3,3.1,0.8,4.6l27.7-0.4
		c0.5-1.4,0.7-2.8,0.7-4.2C386.3,303.7,379.8,297.5,371.7,297.5z M466,297.5c-8,0-14.6,6.2-14.6,13.9c0,1.1,0.1,2.1,0.4,3.1
		c14.6-0.3,25.7-0.5,28.5-0.7c0.2-0.8,0.2-1.6,0.2-2.5C480.6,303.7,474,297.5,466,297.5z"/>
						<path id="ruedas" class="st6" d="M371.7,299.3c-6.7,0-12.1,5.4-12.1,12.1s5.4,12.1,12.1,12.1s12.1-5.4,12.1-12.1
		S378.4,299.3,371.7,299.3z M466,299.3c-6.7,0-12.1,5.4-12.1,12.1s5.4,12.1,12.1,12.1s12.1-5.4,12.1-12.1S472.6,299.3,466,299.3z
		 M348.5,308.5c-0.2-0.2-0.4-0.4-0.7-0.4h-5.2c-0.1,1.5,0,3,0.2,4.5h7c0.5,0,0.9-0.4,0.9-0.9c0-0.2-0.1-0.4-0.2-0.5L348.5,308.5z"/>
						<path id="llantas" class="st7" d="M371.7,303.4c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S376.1,303.4,371.7,303.4z M466,303.4
		c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S470.4,303.4,466,303.4z"/>
						<path id="luz" class="st2" d="M356.9,293.9c-3.5,0.4-8.6,1.3-9.7,2.1s-2.6,3.3-2.4,3.8s1.2,0.6,3.7,0.6s4.9,0.1,6.5-1.3
		c1.3-1.3,2.5-2.7,3.5-4.2C359.1,294.2,358.6,293.6,356.9,293.9z"/>
						<path id="vidrios" class="st8" d="M402.8,273.3c-7.9,3.8-15.6,8.1-23.1,12.8c0,0,5.4,0.1,7-1s12.5-8.7,15.3-10.1
		C404.2,273.9,405.3,273.3,402.8,273.3z M482.4,284.7c-3-2.4-9.7-6.8-14.8-10c-0.7,0.1-1.4,0.4-1.9,0.8c-0.5,0.7,2,2,4.6,4.4
		s5,4.3,5.9,4.8C476.9,284.9,480.3,284.8,482.4,284.7L482.4,284.7z M460.9,279.5c-3.3-3-5.3-5.9-11-6.2c-4.2-0.2-11.1-0.3-18.2-0.2
		c-0.2,3-0.4,8.6-0.4,12.3h3.2c12.5-0.1,28.2-0.6,29.5-0.7S464.2,282.5,460.9,279.5z M412.8,274.6c-5,1.5-9.9,3.7-14.3,6.6
		c-5.9,3.8-7.8,5.5-4.7,5.4c2.5,0,18.9-0.7,31.8-1c0.1-2.5,1-8.9,1.6-12.4l-3.3,0.1C420.1,273.4,416.4,273.8,412.8,274.6z"/>
						<path id="lucesAtras" class="st9" d="M480.8,290.6c-0.9,0-0.9,0.9,0,2.5s3.3,5.2,4.1,5.6c0.8,0.3,1.6,0.5,2.4,0.6v-6.8
		c0-0.6,0-1.3-0.1-1.9L480.8,290.6z M490.4,303.9h-1.8c-0.6,0-1.1,0.4-1.1,1c0,0,0,0,0,0c0,0.7,0.6,1,1.4,1h1.6
		C490.4,305.2,490.4,304.5,490.4,303.9z"/>
					</g>
					<g id="avion">
						<path id="base" class="st2" d="M75.8,36.5c-1.6-0.6-3.2-1.1-4.9-1.4c0,0-1.7-2.8-4.4-2.8H15.4c-4,0-8.3,0.2-8.6,2
		c-0.2,1.3,0.1,2.8,4.2,4.1s10.6,3.8,13.9,3.8h15.5v1.5c0,0,0,0,0,0l9.1,0.5v-2h14c3.9,0,9.4-0.6,11.7-2
		C77.5,38.8,78.5,37.5,75.8,36.5z M12.7,31.7L7,19.3c-0.2-0.4-0.6-0.7-1-0.7H4.3L8.4,32L12.7,31.7z"/>
						<path id="detalles" class="st21" d="M15.3,34.1L15.3,34.1l-14.8,0v0.6c0,0,11.4,1,13.3,1c1.9,0,2.4-0.3,2.4-0.8
		S15.9,34.1,15.3,34.1z M69.3,35.7c0.7,0,1.4-0.2,2-0.5l-0.4-0.1c-0.5,0.3-1,0.4-1.6,0.4c-0.2,0-0.4-0.2-0.4-0.4v-0.6
		c0-0.2,0.2-0.4,0.4-0.4h0.8l-0.2-0.2h-0.6c-0.3,0-0.6,0.3-0.6,0.6v0.6C68.7,35.4,69,35.7,69.3,35.7z M32.7,37.1
		c0.6,0,1-0.8,0.9-1.4c-0.1-0.4-0.3-0.9-0.9-0.9s-1,0.8-0.9,1.4C31.9,36.7,32.2,37.1,32.7,37.1z M32.7,35c0.4,0,0.7,0.4,0.7,0.9
		c0,0.5-0.3,1-0.7,1c-0.4,0-0.7-0.4-0.7-1C32,35.4,32.3,35,32.7,35z M53.9,37.1c0.6,0,1-0.8,0.9-1.4c-0.1-0.4-0.3-0.9-0.9-0.9
		s-1,0.8-0.9,1.4C53.1,36.7,53.4,37.1,53.9,37.1z M53.9,35c0.4,0,0.7,0.4,0.7,0.9c0,0.5-0.3,1-0.7,1s-0.7-0.4-0.7-1
		C53.2,35.4,53.5,35,53.9,35z M56.4,37.1c0.6,0,1-0.8,0.9-1.4c-0.1-0.4-0.3-0.9-0.9-0.9c-0.6,0-1,0.8-0.9,1.4
		C55.6,36.7,55.9,37.1,56.4,37.1z M56.4,35c0.4,0,0.7,0.4,0.7,0.9c0,0.5-0.3,1-0.7,1s-0.7-0.4-0.7-1C55.7,35.4,56,35,56.4,35z
		 M66.6,35.9h1c0.3,0,0.6-0.3,0.6-0.6c0,0,0,0,0,0v-0.6c0-0.3-0.3-0.6-0.6-0.6c0,0,0,0,0,0h-1c-0.3,0-0.6,0.3-0.6,0.6v0.6
		C66,35.7,66.3,35.9,66.6,35.9z M66.3,34.7c0-0.2,0.2-0.4,0.4-0.4h1c0.2,0,0.4,0.2,0.3,0.4v0.6c0,0.2-0.2,0.4-0.3,0.4h-1
		c-0.2,0-0.4-0.2-0.4-0.4V34.7z M58.7,37.1c0.6,0,1-0.8,0.9-1.4c-0.1-0.4-0.3-0.9-0.9-0.9c-0.6,0-1,0.8-0.9,1.4
		C57.9,36.7,58.2,37.1,58.7,37.1z M58.7,35c0.4,0,0.7,0.4,0.7,0.9c0,0.5-0.3,1-0.7,1s-0.7-0.4-0.7-1C58,35.4,58.3,35,58.7,35z
		 M53,40.3l0.4-0.3c0.3-0.2,0.4-0.5,0.4-0.9v-0.8H16.6v1l9.2,0.3l5.9,0.7H17c2.5,0.9,5.2,1.5,7.9,1.8h14v0.1c0,0.9,0.7,1.6,1.5,1.6
		l9.2,0.5h0.1c0.7,0,1.4-0.5,1.6-1.2c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0v-0.7h12.2
		c3.7,0,8.8-0.6,11.3-1.8H53z M49.5,44.2l-9.1-0.5c0,0,0,0,0,0v-3.9c0,0,0.1,0,0.1,0h9V44.2z M51.1,40.3c-0.3-0.5-0.8-0.9-1.4-0.9
		h-9.2c0,0-0.1,0-0.1,0v0c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1
		c-0.1,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1
		c0,0,0,0.1,0,0.1H34l-8.1-1l-9-0.3v-0.5h36.7v0.5c0,0.3-0.1,0.5-0.3,0.7l-0.7,0.6H51.1z M23.1,34.8c-0.6,0-1,0.8-0.9,1.4
		c0.1,0.4,0.4,0.9,0.9,0.9c0.6,0,1-0.8,0.9-1.4C23.9,35.2,23.7,34.8,23.1,34.8z M23.1,36.9c-0.4,0-0.7-0.4-0.7-1
		c0-0.5,0.3-0.9,0.7-0.9c0.4,0,0.7,0.4,0.7,0.9C23.8,36.5,23.5,36.9,23.1,36.9z M51.6,37.1c0.6,0,1-0.8,0.9-1.4
		c-0.1-0.4-0.3-0.9-0.9-0.9s-1,0.8-0.9,1.4C50.7,36.7,51,37.1,51.6,37.1z M51.5,35c0.4,0,0.7,0.4,0.7,0.9c0,0.5-0.3,1-0.7,1
		s-0.7-0.4-0.7-1C50.8,35.4,51.1,35,51.5,35z M49.2,37.1c0.6,0,1-0.8,0.9-1.4c-0.1-0.4-0.3-0.9-0.9-0.9s-1,0.8-0.9,1.4
		C48.4,36.7,48.7,37.1,49.2,37.1z M49.2,35c0.4,0,0.7,0.4,0.7,0.9c0,0.5-0.3,1-0.7,1s-0.7-0.4-0.7-1C48.5,35.4,48.8,35,49.2,35z
		 M37.4,37.1c0.6,0,1-0.8,0.9-1.4c-0.1-0.4-0.3-0.9-0.9-0.9c-0.6,0-1,0.8-0.9,1.4C36.6,36.7,36.9,37.1,37.4,37.1z M37.4,35
		c0.4,0,0.7,0.4,0.7,0.9c0,0.5-0.3,1-0.7,1s-0.7-0.4-0.7-1C36.7,35.4,37,35,37.4,35z M28,37.1c0.6,0,1-0.8,0.9-1.4
		c-0.1-0.4-0.3-0.9-0.9-0.9s-1,0.8-0.9,1.4C27.2,36.7,27.5,37.1,28,37.1z M28,35c0.4,0,0.7,0.4,0.7,0.9c0,0.5-0.3,1-0.7,1
		c-0.4,0-0.7-0.4-0.7-1C27.3,35.4,27.6,35,28,35z M30.4,37.1c0.6,0,1-0.8,0.9-1.4c-0.1-0.4-0.3-0.9-0.9-0.9s-1,0.8-0.9,1.4
		C29.6,36.7,29.9,37.1,30.4,37.1z M30.4,35c0.4,0,0.7,0.4,0.7,0.9c0,0.5-0.3,1-0.7,1c-0.4,0-0.7-0.4-0.7-1C29.7,35.4,30,35,30.4,35z
		 M35.1,37.1c0.6,0,1-0.8,0.9-1.4c-0.1-0.4-0.3-0.9-0.9-0.9s-1,0.8-0.9,1.4C34.3,36.7,34.6,37.1,35.1,37.1z M35.1,35
		c0.4,0,0.7,0.4,0.7,0.9c0,0.5-0.3,1-0.7,1c-0.4,0-0.7-0.4-0.7-1C34.4,35.4,34.7,35,35.1,35z M39.7,37.1c0.6,0,1-0.8,0.9-1.4
		c-0.1-0.4-0.3-0.9-0.9-0.9s-1,0.8-0.9,1.4C38.9,36.7,39.2,37.1,39.7,37.1z M39.7,35c0.4,0,0.7,0.4,0.7,0.9c0,0.5-0.3,1-0.7,1
		s-0.7-0.4-0.7-1C39,35.4,39.3,35,39.7,35z M6,18.6c0.4,0,0.8,0.3,1,0.7l5.7,12.4L8.4,32l0.5,1.6L24.7,33c0,0-5.3-2.2-7.2-4
		c-2.4-2.5-4.6-5.1-6.7-7.8c-1.7-2.2-3-3.9-4.1-3.9H3.9l0.4,1.4H6z M46.9,37.1c0.6,0,1-0.8,0.9-1.4c-0.1-0.4-0.3-0.9-0.9-0.9
		s-1,0.8-0.9,1.4C46,36.7,46.3,37.1,46.9,37.1z M46.8,35c0.4,0,0.7,0.4,0.7,0.9c0,0.5-0.3,1-0.7,1s-0.7-0.4-0.7-1
		C46.1,35.4,46.4,35,46.8,35z M42.2,37.1c0.6,0,1-0.8,0.9-1.4c-0.1-0.4-0.3-0.9-0.9-0.9c-0.6,0-1,0.8-0.9,1.4
		C41.4,36.7,41.7,37.1,42.2,37.1z M42.2,35c0.4,0,0.7,0.4,0.7,0.9c0,0.5-0.3,1-0.7,1s-0.7-0.4-0.7-1C41.5,35.4,41.8,35,42.2,35z
		 M24.5,36.2c0.1,0.4,0.4,0.9,0.9,0.9c0.6,0,1-0.8,0.9-1.4c-0.1-0.4-0.3-0.9-0.9-0.9S24.4,35.6,24.5,36.2z M25.4,35
		c0.4,0,0.7,0.4,0.7,0.9c0,0.5-0.3,1-0.7,1c-0.4,0-0.7-0.4-0.7-1C24.7,35.4,25.1,35,25.4,35z M44.6,37.1c0.6,0,1-0.8,0.9-1.4
		c-0.1-0.4-0.3-0.9-0.9-0.9c-0.6,0-1,0.8-0.9,1.4C43.7,36.7,44,37.1,44.6,37.1z M44.5,35c0.4,0,0.7,0.4,0.7,0.9c0,0.5-0.3,1-0.7,1
		s-0.7-0.4-0.7-1C43.8,35.4,44.1,35,44.5,35z"/>
					</g>

				</svg>

            </div>
        );
    }
}

export default Transporte;