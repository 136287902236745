import React, {Component} from 'react';
import "assets/scss/styles.scss";


class Energia extends Component {
    render() {
        return (
            <div>
				<svg version="1.1" id="Energia" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
					 viewBox="0 0 449.7 295.3">
					<path id="vapor" class="st0" d="M19.7,200.1c-1-1.2-1.4-2-1.4-3.1c0.1-1.2,0.5-2.3,1.4-3.1c1.1-1.1,1.8-2.6,1.8-4.2
	c-0.1-1.6-0.8-3-1.8-4.2c-0.8-0.8-1.3-1.9-1.4-3.1c0.1-1.2,0.5-2.2,1.4-3.1c1.1-1.1,1.8-2.6,1.9-4.2c0-1.7-0.8-2.8-1.8-4.2
	c-0.9-1.2-1.4-2-1.4-3.1c0.1-1.2,0.6-2.3,1.4-3.1c1.1-1.1,1.7-2.6,1.8-4.1h-1.5c-0.1,1.2-0.6,2.3-1.5,3.1c-1.1,1.1-1.7,2.6-1.7,4.1
	c0.1,1.5,0.7,3,1.7,4.1c0.9,1.2,1.5,2,1.5,3.2c-0.1,1.2-0.6,2.3-1.5,3.2c-1.1,1.1-1.7,2.6-1.7,4.1c0.1,1.5,0.7,3,1.7,4.1
	c0.8,0.9,1.4,2,1.5,3.2c-0.1,1.2-0.6,2.3-1.5,3.2c-1.1,1.1-1.7,2.6-1.7,4.1c0.1,1.5,0.7,3,1.7,4.1c0.9,1.2,1.5,2,1.5,3.2
	c-0.1,1.2-0.6,2.4-1.5,3.2c-1.1,1.1-1.7,2.6-1.7,4.1h1.5c0.1-1.2,0.5-2.2,1.4-3.1c1.1-1.1,1.8-2.6,1.9-4.2
	C21.5,202.6,20.8,201.4,19.7,200.1z M13.1,197c0.1-1.2,0.6-2.3,1.4-3.1c1.1-1.1,1.7-2.6,1.8-4.2c-0.1-1.6-0.8-3-1.8-4.2
	c-0.8-0.8-1.3-1.9-1.4-3.1c0.1-1.2,0.5-2.2,1.4-3.1c1.1-1.1,1.8-2.6,1.9-4.2c0-1.7-0.8-2.8-1.8-4.2c-0.9-1.2-1.4-2-1.4-3.1
	c0.1-1.2,0.5-2.3,1.4-3.1c1.1-1.1,1.7-2.6,1.8-4.1h-1.5c-0.1,1.2-0.6,2.3-1.5,3.1c-1.1,1.1-1.7,2.6-1.7,4.1c0.1,1.5,0.7,3,1.7,4.1
	c0.9,1.2,1.5,2,1.5,3.2c-0.1,1.2-0.6,2.3-1.5,3.2c-1.1,1.1-1.7,2.6-1.7,4.1c0.1,1.5,0.7,3,1.7,4.1c0.9,0.9,1.4,2,1.5,3.2
	c-0.1,1.2-0.6,2.3-1.5,3.2c-1.1,1.1-1.7,2.6-1.7,4.1c0.1,1.5,0.7,3,1.7,4.1c0.9,1.2,1.5,2,1.5,3.2c-0.1,1.2-0.6,2.3-1.5,3.2
	c-1.1,1.1-1.7,2.6-1.7,4.1h1.5c0.1-1.2,0.5-2.2,1.4-3.1c1.1-1.1,1.8-2.6,1.9-4.2c0-1.7-0.8-2.8-1.8-4.2
	C13.5,198.9,13.1,198.1,13.1,197z M5.6,175.1c0-1.7-0.8-2.8-1.8-4.2c-0.9-1.2-1.4-2-1.4-3.1c0.1-1.2,0.6-2.3,1.4-3.1
	c1.1-1.1,1.7-2.6,1.8-4.1H4.1c-0.1,1.2-0.6,2.3-1.5,3.1c-1.1,1.1-1.7,2.6-1.7,4.1c0.1,1.5,0.7,3,1.7,4.1c0.9,1.2,1.5,2,1.5,3.2
	c-0.1,1.2-0.6,2.4-1.5,3.2c-1.1,1.1-1.7,2.6-1.7,4.1c0.1,1.5,0.7,3,1.7,4.1c0.8,0.9,1.4,2,1.5,3.2c-0.1,1.2-0.6,2.3-1.5,3.2
	c-1.1,1.1-1.7,2.6-1.7,4.1c0.1,1.5,0.7,3,1.7,4.1c0.9,1.2,1.5,2,1.5,3.2c-0.1,1.2-0.6,2.3-1.5,3.2c-1.1,1.1-1.7,2.6-1.7,4.1h1.5
	c0.1-1.2,0.5-2.2,1.4-3.1c1.1-1.1,1.8-2.6,1.8-4.2c0-1.7-0.8-2.8-1.8-4.2c-0.9-1.2-1.4-2-1.4-3.1c0.1-1.2,0.6-2.3,1.4-3.1
	c1.1-1.1,1.8-2.6,1.8-4.2h0c-0.1-1.6-0.8-3-1.8-4.2c-0.8-0.8-1.3-1.9-1.4-3.1c0.1-1.2,0.5-2.2,1.4-3.1
	C4.9,178.2,5.6,176.7,5.6,175.1z M7.7,197c0.1-1.2,0.5-2.3,1.4-3.1c1.1-1.1,1.7-2.6,1.8-4.2c-0.1-1.6-0.8-3-1.8-4.2
	c-0.8-0.8-1.3-1.9-1.4-3.1c0.1-1.2,0.5-2.2,1.4-3.1c1.1-1.1,1.8-2.6,1.8-4.2c0-1.7-0.8-2.8-1.8-4.2c-0.9-1.2-1.4-2-1.4-3.1
	c0.1-1.2,0.6-2.3,1.4-3.1c1.1-1.1,1.7-2.6,1.8-4.1H9.4c-0.1,1.2-0.6,2.3-1.5,3.1c-1.1,1.1-1.7,2.6-1.7,4.1c0.1,1.5,0.7,3,1.7,4.1
	c0.9,1.2,1.5,2,1.5,3.2c-0.1,1.2-0.6,2.4-1.5,3.2c-1.1,1.1-1.7,2.6-1.7,4.1c0.1,1.5,0.7,3,1.7,4.1c0.8,0.9,1.4,2,1.5,3.2
	c-0.1,1.2-0.6,2.3-1.5,3.2c-1.1,1.1-1.7,2.6-1.7,4.1c0.1,1.5,0.7,3,1.7,4.1c0.9,1.2,1.5,2,1.5,3.2c-0.1,1.2-0.6,2.4-1.5,3.2
	c-1.1,1.1-1.7,2.6-1.7,4.1h1.5c0.1-1.2,0.5-2.2,1.4-3.1c1.1-1.1,1.8-2.6,1.9-4.2c0-1.7-0.8-2.8-1.8-4.2C8.2,198.9,7.7,198.1,7.7,197
	z"/>
					<path id="viento" class="st0" d="M367.9,107.1c-5,0-8.9-2.4-13.1-5.9c-3.8-3.1-8-6-14.1-6c-5.8,0-9.8,2.7-14,6
	c-4.5,3.5-8.1,6-13.1,6c-5.6,0-10-3.2-13.1-5.9c-4.6-4-9.2-6-14-6c-6,0-10.9,3.5-14,6c-3.4,2.8-7.9,6-13.1,6c-5,0-8.9-2.4-13.1-5.9
	c-3.8-3.2-8-6.1-14.1-6.1c-5.8,0-9.8,2.7-14,6c-4.5,3.5-8.1,6-13.1,6c-0.2,0-0.3,0-0.4,0v1.9c0.2,0,0.4,0,0.6,0
	c5.7,0,9.7-2.8,14.4-6.4c4.7-3.6,8-5.6,12.8-5.6c5.4,0,9.3,2.7,12.8,5.6c4,3.3,8.5,6.4,14.4,6.4s10.8-3.4,14.4-6.4
	c2.9-2.4,7.4-5.6,12.8-5.6c4.3,0,8.5,1.8,12.7,5.5c3.1,2.8,8,6.4,14.4,6.4c5.7,0,9.7-2.8,14.4-6.4c4.7-3.6,8-5.6,12.8-5.6
	c5.4,0,9.3,2.7,12.8,5.6c4,3.3,8.5,6.4,14.4,6.4s10.8-3.4,14.4-6.4c2.9-2.4,7.4-5.6,12.8-5.6v-2c-6,0-10.9,3.4-14,6
	C377.7,103.9,373.1,107.1,367.9,107.1z M219.4,43.7c4.7-3.6,8-5.6,12.8-5.6c5.4,0,9.3,2.7,12.8,5.6c4,3.3,8.5,6.4,14.4,6.4
	s10.8-3.5,14.4-6.4c2.9-2.4,7.4-5.6,12.8-5.6c4.3,0,8.5,1.8,12.7,5.5c3.1,2.8,8,6.4,14.4,6.4c5.7,0,9.7-2.8,14.4-6.4
	c4.7-3.6,8-5.6,12.8-5.6c5.4,0,9.3,2.7,12.8,5.6c4,3.3,8.5,6.4,14.4,6.4s10.8-3.4,14.4-6.4c2.9-2.4,7.4-5.6,12.8-5.6v-2
	c-6,0-10.9,3.5-14,6.1c-3.4,2.8-7.9,6-13.1,6c-5,0-8.9-2.4-13.1-5.9c-3.8-3.1-8-6-14.1-6c-5.8,0-9.8,2.7-14,6c-4.5,3.5-8.1,6-13.1,6
	c-5.6,0-10-3.2-13.1-5.9c-4.6-4-9.2-6-14-6c-6,0-10.9,3.5-14,6c-3.4,2.8-7.9,6-13.1,6c-5,0-8.9-2.4-13.1-5.9
	c-3.8-3.1-8-6.1-14.1-6.1c-5.8,0-9.8,2.7-14,6c-4.5,3.5-8.1,6-13.1,6c-0.2,0-0.3,0-0.4,0v1.9c0.1,0,0.3,0,0.4,0
	C210.7,50.1,214.7,47.3,219.4,43.7z M367.9,87.4c-5,0-8.9-2.4-13.1-5.9c-3.8-3.1-8-6.1-14.1-6.1c-5.8,0-9.8,2.7-14,6
	c-4.5,3.5-8.1,6-13.1,6c-5.6,0-10-3.2-13.1-6c-4.6-4-9.2-6-14-6c-6,0-10.9,3.5-14,6c-3.4,2.8-7.9,6-13.1,6c-5,0-8.9-2.4-13.1-5.9
	c-3.8-3.1-8-6-14.1-6c-5.8,0-9.8,2.7-14,6c-4.5,3.5-8.1,6-13.1,6c-0.2,0-0.3,0-0.4,0v2c0.2,0,0.4,0,0.6,0c5.7,0,9.7-2.8,14.4-6.4
	c4.7-3.6,8-5.6,12.8-5.6c5.4,0,9.3,2.7,12.8,5.6c4,3.3,8.5,6.4,14.4,6.4s10.8-3.4,14.4-6.4c2.9-2.4,7.4-5.6,12.8-5.6
	c4.3,0,8.5,1.8,12.7,5.5c3.1,2.8,8,6.5,14.4,6.5c5.7,0,9.7-2.8,14.4-6.4c4.7-3.6,8-5.6,12.8-5.6c5.4,0,9.3,2.7,12.8,5.6
	c4,3.3,8.5,6.4,14.4,6.4s10.8-3.4,14.4-6.4c2.9-2.4,7.4-5.6,12.8-5.6v-2c-6,0-10.9,3.5-14,6C377.7,84.2,373.1,87.4,367.9,87.4z
	 M381.2,61.7c-3.6,2.8-8.1,6-13.3,6c-5,0-8.8-2.4-13.1-5.9c-3.8-3.2-8-6.1-14.1-6.1c-5.8,0-9.8,2.7-14,6c-4.5,3.5-8.1,6-13.1,6
	c-5.6,0-10-3.2-13.1-6c-4.6-4-9.2-6-14-6c-6,0-10.9,3.5-14,6c-3.4,2.8-7.9,6-13.1,6c-5,0-8.9-2.4-13.1-5.9c-3.8-3.1-8-6.1-14.1-6.1
	c-5.8,0-9.8,2.7-14,6c-4.5,3.5-8.1,6-13.1,6c-0.2,0-0.3,0-0.4,0v2c0.2,0,0.4,0,0.6,0c5.7,0,9.7-2.8,14.4-6.4c4.7-3.6,8-5.6,12.8-5.6
	c5.4,0,9.3,2.7,12.8,5.6c4,3.3,8.5,6.4,14.4,6.4s10.8-3.4,14.4-6.4c2.9-2.4,7.4-5.6,12.8-5.6c4.3,0,8.5,1.8,12.7,5.5
	c3.1,2.8,8,6.4,14.4,6.4c5.7,0,9.7-2.8,14.4-6.4c4.7-3.6,8-5.6,12.8-5.6c5.4,0,9.3,2.7,12.8,5.6c4,3.3,8.5,6.4,14.4,6.4
	s10.8-3.4,14.4-6.4c2.9-2.4,7.4-5.6,12.8-5.6v-2C389.3,55.7,384.4,59.1,381.2,61.7z"/>
					<path class="st1" d="M0,0v160.6h23.7V197H0v98.3h449.7V0H0z M393.9,127.5H270.6V19.1h123.3V127.5z"/>
					<g id="pava">
						<rect x="98.3" y="176.2" class="st2" width="6.7" height="6.7"/>
						<path class="st3" d="M160,225.3c0-6.6-0.9-11.1-3.9-14.1c-2.6-2.3-5.2-4.4-8-6.4v-10.2c0-4.8,2.5-11.6,7.7-20.9
		c6-10.7,5-23.4-2.3-31.7c-8.3-9.3-27.2-14.6-51.9-14.6s-43.6,5.3-51.9,14.6c-7.4,8.3-8.3,21-2.3,31.7c5.2,9.2,7.7,16,7.7,20.9v9.6
		c-3.1,2.2-6.1,4.5-8.9,7c-2.2,2.2-3.2,5.1-3.7,9v-0.1c0,0-6.8,0.8-11.9-8.2S22,197.1,22,197.1H1c5.6,5.2,9.6,11.8,11.6,19.1
		c3,11.8,5.1,38.8,28.8,39.8c0,0.6,0,1,0,1.3c0.2,3.4,2.1,11.2,10.7,11.2H150c8.6,0,10.5-7.9,10.7-11.2S160,231.9,160,225.3z
		 M129.8,186.5c-4.7-1.1-25.3-5.2-28.7-5.2s-24,4.1-28.7,5.2c-5.5,1.2-9.3,6.2-9.1,11.8c0,0-1.7,1.2-4,2.9v-6.5
		c0-5.6-2.6-12.9-8.2-22.9c-5.1-9.1-4.4-19.9,1.8-26.9c7.3-8.3,25.6-13.2,48.7-13.2s41.4,4.9,48.7,13.2c6.2,7,6.9,17.8,1.8,26.9
		c-5.6,10-8.3,17.3-8.3,22.9v7.2c-2.8-2-4.9-3.5-4.9-3.5C139.2,192.7,135.3,187.7,129.8,186.5z"/>
						<path class="st4" d="M137.7,142.9c-0.9,0-1.8-0.2-2.6-0.5c-0.1,0-12.2-4.7-33.5-4.7S68.4,142,68.3,142c-3.6,1.3-7.6-0.6-8.9-4.3
		c-1.3-3.6,0.5-7.5,4.1-8.9c0.6-0.2,14-5.1,38.1-5.1s38.1,5.5,38.7,5.7c3.6,1.4,5.3,5.5,3.9,9.1C143.1,141.2,140.5,143,137.7,142.9
		L137.7,142.9z"/>
						<path class="st5" d="M96.6,166.7h10c2.8,0,5.1,2.3,5.1,5.1l0,0c0,2.8-2.3,5.1-5.1,5.1h-10c-2.8,0-5.1-2.3-5.1-5.1l0,0
		C91.5,169,93.8,166.7,96.6,166.7z"/>
						<path class="st6" d="M98.7,181.5c0,0-20.1,3.7-22.5,6.3s-4.3,4.3-4.3,7.9s-4.3,6.8-7.5,9.2c-3.2,2.4-7.3,6.8-7.9,12.8
		s-2.6,36.8-2.2,40.5s3.8,9,6.4,10.5h18.2c0,0-4.7-3.6-4.9-8.2c-0.2-4.7,0.6-42.8,1.4-47.4s7.7-9.9,8.6-12.6s0.2-6.2,0.9-9
		s15.6-9.9,15.6-9.9L98.7,181.5z"/>
						<path class="st7" d="M103.3,181.5c0,0,20.1,3.7,22.5,6.3s4.3,4.3,4.3,7.9s4.3,6.8,7.5,9.2s7.3,6.8,7.9,12.8s2.6,36.7,2.2,40.5
		s-3.8,9-6.4,10.5h-18.2c0,0,4.7-3.6,4.9-8.2s-0.6-42.7-1.3-47.4s-7.7-9.9-8.6-12.6s-0.2-6.2-0.9-9s-15.6-9.9-15.6-9.9H103.3z"/>
						<path class="st7" d="M41.3,256l1.2-35.9c-1.7,0.1-3.4-0.3-4.9-1.1L36.8,246c-9.3-1.2-17.2-7.1-21-15.6c3,11.3,8.6,23.5,23,25.4H39
		C39.7,255.9,40.5,256,41.3,256z"/>
						<rect x="63.2" y="196.6" class="st5" width="75.8" height="1.7"/>
						<path class="st2" d="M180.4,285.3h-22.3l-1.9-11.8h10c1.3,0.1,2.5-0.9,2.6-2.3c0.1-1.3-0.9-2.5-2.3-2.6c-0.1,0-0.2,0-0.3,0H36.1
		c-1.3-0.1-2.5,0.9-2.6,2.3c-0.1,1.3,0.9,2.5,2.3,2.6c0.1,0,0.2,0,0.3,0h10.1l-1.8,11.8H21.9c-1.3,0-2.4,1.1-2.4,2.4
		s1.1,2.4,2.4,2.4h158.5c1.3,0,2.4-1.1,2.4-2.4S181.7,285.3,180.4,285.3z M49.1,285.3l1.9-11.8h47.7v8.1h-25c-1.2,0-2.2,1-2.2,2.2
		v1.5H49.1z M130.7,285.3v-1.5c0-1.2-1-2.2-2.2-2.2h-24.9v-8.1h47.7l1.9,11.8H130.7z"/>
						<path class="st8" d="M87.8,273.5H59.9c2.5,7,9.7,9.2,16.5,9.5c3.2,0.1,3.8-1.3,3.7-2.4c0.9,1.4,2.2,2.4,3.8,2.9
		c4.5,1.2,5.9-0.3,5.3-1.9C88.5,278.9,88,276.2,87.8,273.5z M113.2,273.5c-0.2,2.7-0.7,5.4-1.6,8.1c-0.6,1.6,0.9,3,5.7,1.9
		c1.7-0.4,3.1-1.5,4.1-2.9c-0.1,1.1,0.5,2.5,4,2.4c7.3-0.3,15.1-2.4,17.7-9.5L113.2,273.5z"/>
						<path class="st9" d="M84.8,281.5c-3.1-1.8-3.7-6-3.9-8.1h-1.7c0.1,1.5,0.4,3,0.9,4.4C81.8,282.5,86.1,282.3,84.8,281.5z
		 M73.4,280.5c-2.1-1.8-4.1-3.9-4.6-7h-3c0,0,0,6.2,8.3,8.7C74.1,282.2,75.5,282.2,73.4,280.5L73.4,280.5z M116.4,281.5
		c-1.4,0.8,3.1,1,5-3.6c0.6-1.4,0.9-2.9,1-4.4h-1.8C120.4,275.5,119.8,279.7,116.4,281.5z M133.5,273.5c-0.5,3.1-2.6,5.2-4.9,7
		s-0.8,1.7-0.8,1.7c8.9-2.5,8.9-8.7,8.9-8.7L133.5,273.5z"/>
					</g>
					<g id="ventilador">
						<g id="aspas">
							<path class="st1" d="M228.7,2c-39.6,0-71.8,32.1-71.8,71.8s32.1,71.8,71.8,71.8s71.8-32.1,71.8-71.8c0,0,0,0,0,0
			C300.4,34.2,268.3,2,228.7,2z"/>
							<path class="st10" d="M229.2,32.5c9.8-15.4,17.4-17.2,10.1-21.9s-19-3.8-25.1-2.8c-16.4,2.5-33.2,13.9-38.1,26.1
			s-3.6,31.7,9.9,38.1s41.8,1.5,41.8,1.5S219.4,47.9,229.2,32.5z M192,93c-18.2-0.6-23.7-6.2-24,2.4s6.5,18.3,10.3,23.1
			c10.4,12.8,28.8,21.5,41.8,19.5c13-2,29.1-13,27.7-27.9s-20-36.8-20-36.8S210.2,93.6,192,93z M287.5,47.2
			c-8.3-10.2-26-18.4-38.1-9.7s-21.5,35.9-21.5,35.9s26.4,4.9,35.2,20.9s6.7,23.6,14.3,19.5s12.5-14.9,14.6-20.6
			C297.7,77.6,295.8,57.3,287.5,47.2z"/>
						</g>
						<polyline class="st5" points="239.1,145.7 239.1,173.9 218.2,173.9 218.2,145.7 	"/>
						<path class="st7" d="M270.9,189.5c-1.7-4.8-4.7-9-8.6-12.2c-3.6-2.8-12.6-3.4-21.1-3.4h-25.2c-8.4,0-17.4,0.6-21.1,3.4
		c-3.9,3.2-6.9,7.5-8.6,12.2c-1.3,4.1-1.1,6.2,6.5,6.2h71.6C272,195.7,272.2,193.6,270.9,189.5z M228.7,168.9c2.1,0,3.8-1.7,3.8-3.8
		s-1.7-3.8-3.8-3.8c-2.1,0-3.8,1.7-3.8,3.8c0,0,0,0,0,0C224.9,167.2,226.6,168.9,228.7,168.9L228.7,168.9L228.7,168.9z M228.7,157.1
		c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8s-3.8,1.7-3.8,3.8c0,0,0,0,0,0C224.9,155.4,226.6,157.1,228.7,157.1L228.7,157.1z"/>
						<circle class="st1" cx="228.7" cy="73.8" r="22"/>
						<path class="st6" d="M301.4,74.3v-1h-12.6c0-1.2-0.1-2.5-0.1-3.7l12.6-1l-0.1-1l-12.6,1c-0.1-1.3-0.2-2.6-0.5-3.9l12.5-2l-0.2-1
		l-12.5,2c-0.2-1.2-0.4-2.3-0.7-3.5l12.3-3l-0.2-1l-12.3,3c-0.3-1.2-0.6-2.4-1-3.6l12-3.9l-0.3-1l-12,3.9c-0.4-1.2-0.8-2.4-1.3-3.5
		l11.7-4.8l-0.4-0.9L284,50.3c-0.6-1.3-1.2-2.6-1.8-3.8l11.2-5.8l-0.5-0.9l-11.2,5.8c-0.6-1-1.1-2.1-1.8-3.1l10.8-6.7l-0.5-0.8
		l-10.8,6.7c-0.7-1.1-1.4-2.1-2.1-3.1l10.2-7.5l-0.6-0.8l-10.2,7.5c-0.7-1-1.5-1.9-2.3-2.8l9.6-8.3l-0.7-0.8l-9.6,8.3
		c-0.7-0.8-1.5-1.7-2.3-2.5l9-9l-0.7-0.7l-9,9c-1-1-2.1-2-3.2-2.9l8.1-9.7l-0.8-0.6l-8.1,9.7c-1-0.8-2-1.6-3-2.3l7.3-10.3l-0.8-0.6
		L263,24.4c-0.9-0.7-1.9-1.3-2.9-1.9l6.5-10.8l-0.9-0.5l-6.6,10.9c-1.1-0.7-2.3-1.3-3.5-1.9l5.6-11.3l-0.9-0.4l-5.6,11.3
		c-1.1-0.5-2.2-1-3.3-1.5l4.7-11.7l-0.9-0.4l-4.7,11.7c-1.2-0.5-2.4-0.9-3.5-1.3L251,4.5l-1-0.3l-3.8,12.1c-1.1-0.3-2.3-0.7-3.4-0.9
		L245.6,3l-1-0.2l-2.9,12.3c-1.1-0.3-2.3-0.5-3.4-0.7l2-12.5l-1-0.2l-1.9,12.5c-1.2-0.2-2.4-0.3-3.6-0.4l1-12.6l-1-0.1l-1,12.6
		c-1.2-0.1-2.5-0.1-3.7-0.1V1h-1v12.6c-1.4,0-2.9,0.1-4.2,0.2l-1.1-12.6l-1,0.1l1.1,12.6c-1.2,0.1-2.4,0.3-3.5,0.4l-2.1-12.5l-1,0.2
		l2,12.5c-1.2,0.2-2.5,0.5-3.7,0.8l-3-12.3l-1,0.2l3,12.3c-1.2,0.3-2.3,0.6-3.5,1l-4-12l-1,0.3l4,12c-1.1,0.4-2.1,0.8-3.2,1.2
		l-4.8-11.7l-0.9,0.4l4.9,11.7c-1.2,0.5-2.4,1.1-3.5,1.6l-5.8-11.3l-0.9,0.5l5.8,11.3c-1.2,0.6-2.3,1.3-3.4,1.9l-6.6-10.7l-0.9,0.5
		l6.7,10.8c-1.1,0.7-2.1,1.4-3.1,2.1l-7.5-10.2l-0.8,0.6l7.5,10.2c-1,0.7-1.9,1.5-2.8,2.3l-8.3-9.6l-0.8,0.7l8.3,9.5
		c-0.8,0.7-1.6,1.5-2.4,2.3l-9-9l-0.7,0.7l9,9c-0.8,0.8-1.5,1.6-2.2,2.4l-9.6-8.3l-0.7,0.8l9.6,8.3c-0.8,0.9-1.5,1.8-2.3,2.8
		L170.5,30l-0.6,0.8l10.2,7.6c-0.7,1-1.4,2-2.1,3.1l-10.7-6.7l-0.5,0.8l10.7,6.7c-0.7,1.1-1.3,2.2-1.9,3.3l-11.2-5.8l-0.5,0.9
		l11.2,5.8c-0.6,1.3-1.3,2.5-1.8,3.8l-11.7-4.8l-0.4,0.9l11.7,4.8c-0.5,1.3-1,2.6-1.5,4l-12.1-3.8l-0.3,1l12.1,3.8
		c-0.4,1.1-0.6,2.3-0.9,3.4l-12.3-2.9l-0.2,1l12.3,2.8c-0.3,1.2-0.5,2.5-0.7,3.7l-12.5-1.9l-0.1,1l12.5,1.9
		c-0.2,1.2-0.3,2.4-0.4,3.6L156.1,68l-0.1,1l12.6,0.9c-0.1,1.1-0.1,2.2-0.1,3.3h-12.6v1h12.6c0,1.2,0.1,2.5,0.1,3.7l-12.6,1l0.1,1
		l12.6-1c0.1,1.3,0.2,2.6,0.4,3.9l-12.5,2l0.2,1l12.5-2c0.2,1.2,0.4,2.3,0.7,3.5l-12.3,3l0.2,1l12.3-3c0.3,1.2,0.6,2.4,1,3.6
		l-12,3.9l0.3,1l12-3.9c0.4,1.2,0.8,2.4,1.3,3.5l-11.7,4.8l0.4,0.9l11.7-4.9c0.6,1.3,1.2,2.6,1.8,3.8l-11.2,5.9l0.5,0.9l11.2-5.9
		c0.6,1,1.1,2.1,1.8,3.1l-10.8,6.7l0.5,0.9l10.8-6.7c0.7,1.1,1.4,2.1,2.1,3.1l-10.2,7.5l0.6,0.8l10.2-7.5c0.7,1,1.5,1.9,2.3,2.8
		l-9.6,8.3l0.7,0.8l9.6-8.3c0.7,0.8,1.5,1.6,2.3,2.4l-9,9l0.7,0.7l9-9c1,1,2.1,2,3.2,2.9l-8.1,9.7l0.8,0.6l8.1-9.7
		c1,0.8,2,1.6,3,2.3l-7.3,10.3l0.8,0.6l7.4-10.3c0.9,0.7,1.9,1.3,2.9,1.9l-6.6,10.8l0.9,0.5l6.6-10.8c1.1,0.7,2.3,1.3,3.5,1.9
		l-5.6,11.3l0.9,0.4l5.6-11.3c1.1,0.5,2.2,1,3.3,1.5l-4.7,11.7l0.9,0.4l4.7-11.7c1.2,0.5,2.4,0.9,3.6,1.3l-3.9,12.1l1,0.3l3.8-12.1
		c1.1,0.4,2.3,0.6,3.4,0.9l-2.9,12.3l1,0.2l2.9-12.3c1.1,0.2,2.3,0.5,3.4,0.7l-2,12.5l1,0.1l1.9-12.5c1.2,0.2,2.4,0.3,3.6,0.4
		l-1,12.6l1,0.1l1-12.6c1.2,0.1,2.5,0.1,3.7,0.1v12.6h1v-12.7c1.4,0,2.9-0.1,4.2-0.2l1.1,12.6l1-0.1l-1.1-12.6
		c1.2-0.1,2.4-0.3,3.5-0.4l2.1,12.4l1-0.2l-2-12.5c1.2-0.2,2.5-0.5,3.7-0.8l3,12.3l1-0.2l-3-12.3c1.2-0.3,2.3-0.6,3.5-1l4,12l1-0.3
		l-4-12c1.1-0.4,2.1-0.8,3.2-1.2l4.8,11.7l0.9-0.4l-4.9-11.7c1.2-0.5,2.4-1.1,3.5-1.6l5.8,11.3l0.9-0.5l-5.8-11.3
		c1.2-0.6,2.3-1.3,3.4-1.9l6.7,10.8l0.9-0.5l-6.7-10.8c1.1-0.7,2.1-1.4,3.1-2.1l7.4,10.2l0.8-0.6l-7.5-10.2c1-0.7,1.9-1.5,2.8-2.3
		l8.3,9.6l0.8-0.6l-8.3-9.6c0.8-0.7,1.6-1.5,2.4-2.3l9,9l0.7-0.7l-9-9c0.8-0.8,1.5-1.6,2.2-2.4l9.6,8.3l0.7-0.8l-9.6-8.3
		c0.8-0.9,1.5-1.8,2.3-2.8l10.2,7.6l0.6-0.8l-10.2-7.6c0.7-1,1.4-2,2.1-3.1l10.7,6.7l0.5-0.9l-10.7-6.7c0.7-1.1,1.3-2.2,1.9-3.3
		l11.2,5.8l0.5-0.9l-11.2-5.8c0.6-1.3,1.3-2.5,1.8-3.8l11.7,4.8l0.4-0.9l-11.7-4.8c0.5-1.3,1-2.7,1.5-4l12.1,3.8l0.3-0.9l-12.1-3.8
		c0.4-1.1,0.6-2.3,0.9-3.4l12.3,2.9l0.2-1l-12.3-2.9c0.3-1.2,0.5-2.5,0.7-3.7l12.5,1.9l0.1-1l-12.5-1.9c0.2-1.2,0.3-2.4,0.4-3.6
		l12.6,0.9l0.1-1l-12.6-0.9c0.1-1.1,0.1-2.2,0.1-3.3L301.4,74.3z M287.7,77.5l-37.1-2.7c0,0.3,0,0.7-0.1,1l37.1,2.7
		c-0.1,1.2-0.2,2.4-0.4,3.5l-36.8-5.5c0,0.3-0.1,0.7-0.1,1l36.8,5.5c-0.2,1.2-0.4,2.4-0.7,3.7l-36.2-8.4c-0.1,0.3-0.1,0.7-0.2,1
		l36.2,8.4c-0.3,1.1-0.6,2.2-0.9,3.3l-35.5-11.2c-0.1,0.3-0.2,0.7-0.3,1L284.9,92c-0.4,1.3-0.9,2.6-1.4,3.9l-34.3-14.2
		c-0.1,0.3-0.2,0.6-0.4,0.9l34.4,14.2c-0.5,1.3-1.1,2.5-1.8,3.8l-33-17.1l-0.5,0.9l33,17.1c-0.6,1.1-1.2,2.2-1.8,3.2L247.5,85
		c-0.2,0.3-0.4,0.6-0.5,0.8l31.5,19.7c-0.7,1-1.4,2-2.1,3l-29.8-22.1c-0.2,0.3-0.4,0.5-0.6,0.8l29.8,22.1c-0.7,0.9-1.5,1.9-2.2,2.7
		l-28-24.3l-0.6,0.8l28,24.4c-0.7,0.8-1.4,1.6-2.1,2.3l-26.3-26.3c-0.2,0.2-0.5,0.5-0.7,0.7l26.3,26.3c-0.8,0.8-1.6,1.5-2.4,2.2
		l-24.3-28.1l-0.8,0.7l24.3,28.1c-0.9,0.8-1.8,1.5-2.8,2.2l-22.1-29.9l-0.8,0.6l22.1,29.9c-1,0.7-2,1.4-3.1,2.1l-19.6-31.5
		c-0.3,0.2-0.6,0.4-0.9,0.5l19.6,31.5c-1.1,0.7-2.2,1.3-3.4,1.9l-17-33.1l-0.9,0.5l17,33c-1.1,0.6-2.3,1.1-3.4,1.6l-14.2-34.3
		l-0.9,0.4l14.2,34.4c-1,0.4-2.1,0.8-3.1,1.2L236,94.5c-0.3,0.1-0.6,0.2-1,0.3l11.7,35.3c-1.1,0.4-2.3,0.7-3.4,1l-9-36.1
		c-0.3,0.1-0.6,0.2-1,0.2l8.9,36.1c-1.2,0.3-2.4,0.5-3.6,0.8l-6-36.7c-0.3,0.1-0.7,0.1-1,0.2l6,36.7c-1.1,0.2-2.3,0.3-3.5,0.4
		l-3.2-37l-1,0.1l3.2,37c-1.4,0.1-2.8,0.2-4.2,0.2V95.7h-1v37.2c-1.2,0-2.4-0.1-3.6-0.1l2.9-37l-1-0.1l-2.9,37.1
		c-1.2-0.1-2.3-0.2-3.5-0.4l5.7-36.7l-1-0.2l-5.7,36.7c-1.1-0.2-2.2-0.4-3.3-0.7l8.4-36.2c-0.3-0.1-0.6-0.1-1-0.2l-8.4,36.2
		c-1.1-0.3-2.2-0.6-3.4-0.9l11.1-35.5c-0.3-0.1-0.6-0.2-1-0.3l-11,35.4c-1.2-0.4-2.3-0.8-3.5-1.2l13.9-34.5
		c-0.3-0.1-0.6-0.2-0.9-0.4l-13.9,34.5c-1.1-0.5-2.1-0.9-3.2-1.4l16.5-33.4l-0.9-0.4L202,126.5c-1.2-0.6-2.3-1.2-3.4-1.9l19.2-31.8
		c-0.3-0.2-0.6-0.3-0.9-0.5l-19.2,31.8c-0.9-0.6-1.9-1.2-2.8-1.8l21.5-30.3l-0.8-0.6l-21.5,30.3c-1-0.7-2-1.5-3-2.3l23.9-28.5
		l-0.8-0.6l-23.8,28.5c-1.1-0.9-2.1-1.9-3.1-2.8l26.3-26.3c-0.2-0.2-0.5-0.5-0.7-0.7l-26.3,26.3c-0.8-0.8-1.5-1.6-2.2-2.4l28.1-24.3
		c-0.2-0.2-0.4-0.5-0.7-0.8L183.6,112c-0.8-0.9-1.5-1.8-2.2-2.8l29.9-22.1c-0.2-0.3-0.4-0.5-0.6-0.8l-29.9,22.1
		c-0.7-1-1.4-2-2.1-3.1l31.6-19.6c-0.2-0.3-0.4-0.6-0.5-0.8l-31.6,19.7c-0.6-1-1.2-2-1.7-3l33-17.2c-0.2-0.3-0.3-0.6-0.5-0.9
		l-33,17.1c-0.6-1.2-1.2-2.5-1.8-3.8l34.3-14.2c-0.1-0.3-0.3-0.6-0.4-0.9l-34.3,14.2c-0.5-1.1-0.9-2.3-1.3-3.5L207.9,81
		c-0.1-0.3-0.2-0.6-0.3-1l-35.3,11.6c-0.4-1.1-0.7-2.3-1-3.5l36.1-8.7c-0.1-0.3-0.2-0.7-0.2-1l-36.1,8.7c-0.3-1.1-0.5-2.2-0.7-3.4
		l36.7-5.9c-0.1-0.3-0.1-0.7-0.2-1l-36.7,5.9c-0.2-1.3-0.3-2.5-0.4-3.8l37.1-2.9c0-0.3-0.1-0.7-0.1-1l-37.1,2.9
		c-0.1-1.2-0.1-2.4-0.1-3.6h37.2c0-0.2,0-0.3,0-0.5s0-0.3,0-0.5h-37.2c0-1.1,0.1-2.2,0.1-3.3l37.1,2.7c0-0.3,0-0.7,0.1-1L169.7,69
		c0.1-1.2,0.2-2.4,0.4-3.5l36.8,5.5c0-0.3,0.1-0.7,0.1-1l-36.7-5.5c0.2-1.2,0.4-2.4,0.7-3.6l36.2,8.4c0.1-0.3,0.1-0.7,0.2-1
		l-36.2-8.4c0.3-1.1,0.6-2.2,0.9-3.4l35.5,11.2c0.1-0.3,0.2-0.6,0.3-1l-35.5-11.1c0.4-1.3,0.9-2.6,1.4-3.9l34.3,14.2
		c0.1-0.3,0.2-0.6,0.4-0.9l-34.4-14.2c0.5-1.3,1.1-2.5,1.8-3.8l33,17.1c0.1-0.3,0.3-0.6,0.5-0.9l-33-17.2c0.6-1.1,1.2-2.2,1.8-3.2
		l31.5,19.7c0.2-0.3,0.4-0.6,0.5-0.8L178.8,42c0.7-1,1.4-2,2.1-3l29.8,22.1c0.2-0.3,0.4-0.5,0.6-0.8l-29.8-22.2
		c0.7-0.9,1.5-1.8,2.2-2.7l28,24.4c0.2-0.3,0.4-0.5,0.6-0.8l-28-24.4c0.7-0.8,1.4-1.6,2.2-2.3l26.3,26.3c0.2-0.2,0.5-0.5,0.7-0.7
		l-26.3-26.3c0.8-0.8,1.6-1.5,2.4-2.2l24.3,28.1l0.8-0.7l-24.3-28.1c0.9-0.8,1.8-1.5,2.8-2.2l22.1,29.9c0.3-0.2,0.5-0.4,0.8-0.6
		l-22.1-29.9c1-0.7,2-1.4,3.1-2.1l19.6,31.6c0.3-0.2,0.6-0.4,0.9-0.5l-19.6-31.6c1.1-0.7,2.2-1.3,3.4-1.9l17,33.1l0.9-0.5l-17-33.1
		c1.1-0.6,2.3-1.1,3.4-1.6l14.2,34.3c0.3-0.1,0.6-0.3,0.9-0.4L206.5,19c1-0.4,2.1-0.8,3.1-1.2l11.7,35.3c0.3-0.1,0.6-0.2,1-0.3
		l-11.7-35.3c1.1-0.4,2.3-0.7,3.4-1l8.9,36.1c0.3-0.1,0.6-0.2,1-0.2l-8.9-36.1c1.2-0.3,2.4-0.5,3.6-0.7l6,36.7l1-0.2l-6-36.7
		c1.1-0.2,2.3-0.3,3.5-0.4l3.2,37l1-0.1l-3.2-37c1.4-0.1,2.8-0.2,4.2-0.2v37.1h1V14.6c1.2,0,2.4,0.1,3.6,0.2l-2.9,37
		c0.3,0,0.7,0,1,0.1l2.9-37.1c1.2,0.1,2.3,0.2,3.5,0.4L231.6,52l1,0.2l5.7-36.7c1.1,0.2,2.2,0.4,3.3,0.7l-8.4,36.2l1,0.2l8.4-36.2
		c1.1,0.3,2.2,0.6,3.4,0.9l-11.2,35.5l1,0.3l11.1-35.5c1.2,0.4,2.3,0.8,3.5,1.2l-13.9,34.5c0.3,0.1,0.6,0.2,0.9,0.4l14-34.5
		c1.1,0.5,2.1,0.9,3.2,1.4L238,53.9c0.3,0.1,0.6,0.3,0.9,0.4L255.4,21c1.2,0.6,2.3,1.2,3.4,1.9l-19.2,31.8c0.3,0.2,0.6,0.3,0.9,0.5
		l19.2-31.8c1,0.6,1.9,1.2,2.8,1.8L241,55.6c0.3,0.2,0.5,0.4,0.8,0.6l21.5-30.3c1,0.7,2,1.5,3,2.3l-23.9,28.5l0.8,0.6L267,28.8
		c1.1,0.9,2.1,1.8,3.1,2.8l-26.3,26.3l0.7,0.7l26.3-26.3c0.8,0.8,1.5,1.6,2.2,2.4L244.9,59c0.2,0.2,0.4,0.5,0.7,0.8l28.1-24.3
		c0.8,0.9,1.5,1.8,2.2,2.8L246,60.3c0.2,0.3,0.4,0.5,0.6,0.8l29.9-22.1c0.7,1,1.4,2,2.1,3.1L247,61.8c0.2,0.3,0.4,0.6,0.5,0.8
		L279.1,43c0.6,1,1.2,2,1.7,3l-33,17.2c0.2,0.3,0.3,0.6,0.5,0.9l33-17.2c0.6,1.2,1.2,2.5,1.8,3.8l-34.4,14.2
		c0.1,0.3,0.3,0.6,0.4,0.9l34.3-14.2c0.5,1.1,0.9,2.3,1.3,3.5l-35.3,11.4c0.1,0.3,0.2,0.6,0.3,0.9L285,56c0.4,1.2,0.7,2.3,1,3.5
		l-36.1,8.7c0.1,0.3,0.2,0.6,0.2,1l36.2-8.7c0.3,1.1,0.5,2.2,0.7,3.4l-36.7,5.9c0.1,0.3,0.1,0.7,0.2,1l36.7-5.9
		c0.2,1.3,0.3,2.5,0.4,3.8l-37.1,2.9c0,0.3,0,0.7,0.1,1l37-2.9c0.1,1.2,0.1,2.4,0.1,3.6h-37.2c0,0.2,0,0.3,0,0.5s0,0.3,0,0.5h37.2
		C287.8,75.4,287.7,76.4,287.7,77.5z"/>
						<circle class="st11" cx="228.7" cy="73.8" r="18.4"/>
						<path class="st6" d="M243.2,177.8h-41.5c-1.1-0.2-2.1,0.5-2.3,1.6s0.5,2.1,1.6,2.3c0.2,0,0.5,0,0.7,0h41.5c1.1,0.2,2.1-0.5,2.3-1.6
		s-0.5-2.1-1.6-2.3C243.7,177.8,243.5,177.8,243.2,177.8z M250.3,177.8c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2
		C252.3,178.7,251.4,177.8,250.3,177.8L250.3,177.8z"/>
						<path class="st10" d="M228.7,147.5c-40.7,0-73.8-33-73.8-73.8S187.9,0,228.7,0s73.8,33,73.8,73.8c0,0,0,0,0,0
		C302.4,114.5,269.4,147.5,228.7,147.5z M228.7,2c-39.6,0-71.8,32.1-71.8,71.8s32.1,71.8,71.8,71.8s71.8-32.1,71.8-71.8l0,0
		C300.4,34.2,268.3,2,228.7,2z"/>
					</g>
					<g id="medidor">
						<path class="st12" d="M329.8,205.4c6.9-3.9,14.6-6,22.6-6.2v-17.4c-11.1,0.2-21.9,3.2-31.5,8.7L329.8,205.4z"/>
						<path class="st13" d="M296.5,214.7l15.3,8.4c3.9-6.7,9.4-12.4,16-16.5l-8.9-15C309.6,197.4,301.9,205.3,296.5,214.7z"/>
						<path class="st14" d="M287.8,247.5h17.4c0-7.8,1.8-15.4,5.4-22.3l-15.2-8.4C290.4,226.2,287.8,236.8,287.8,247.5z"/>
						<path class="st15" d="M354.6,181.8v17.4c7.9,0.2,15.7,2.3,22.6,6.2l8.9-15C376.5,184.9,365.7,181.9,354.6,181.8z"/>
						<path class="st16" d="M388.1,191.6l-8.9,15c6.6,4.2,12.1,9.8,16,16.5l15.3-8.4C405,205.3,397.4,197.4,388.1,191.6z"/>
						<path class="st17" d="M411.6,216.8l-15.3,8.4c3.6,6.9,5.5,14.5,5.5,22.3h17.4C419.2,236.8,416.6,226.2,411.6,216.8z"/>
						<g id="indicador">
							<circle class="st1" cx="353.2" cy="247.5" r="41.9"/>
							<path class="st2" d="M358.8,247.5c0-1.9-1-3.6-2.5-4.6l-3.1-37.4l-2.9,37.2l0,0c-2.6,1.6-3.5,5-1.9,7.7c0.3,0.5,0.7,1,1.2,1.4l0,0
			v4.6h7.3v-4.8l0,0C358.1,250.6,358.8,249,358.8,247.5z M353.2,251.6c-2.3,0-4.2-1.9-4.2-4.2s1.9-4.2,4.2-4.2
			c2.3,0,4.2,1.9,4.2,4.2c0,0,0,0,0,0C357.4,249.8,355.5,251.6,353.2,251.6z"/>
						</g>
					</g>
				</svg>
            </div>
        );
    }
}

export default Energia;