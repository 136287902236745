import React, {Component} from 'react';
import "assets/scss/styles.scss";


class Reciclaje extends Component {
    render() {
        return (
            <div>
                <svg version="1.1" id="Reciclaje" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     viewBox="0 0 503.02 334.69">
                    <g id="brazoIzq">
                        <path id="manoIzq" className="st0" d="M173,50.8c7.9,3.6,4.6,4.4,15.5,8.2c13,4.5,24.9,6.2,32,7.5c4,0.7,5.1,6.4-3.4,5.8
		s-21.4-2.7-21.4-2.7s2.9,2.3,23.8,6.5c7.6,1.6,6.6,6.8-1.5,6.5c-8.4-0.3-25.4-3.5-25.4-3.5s12,5.7,21.6,9c7.1,2.4,5.9,7.4-3.9,5.6
		c-10.5-2-22.2-5.6-22.2-5.6s11.8,6.1,17.1,8.7s5.3,7.9-4.9,4c-4.7-1.8-9.6-3.4-14.5-4.7c-9.4-2.6-14.9-2.2-27.3-9.3L173,50.8z"/>
                        <g id="botellaIzq">
                            <path className="st1" d="M155.5,95.8l37.3,18.4l1.2-2.4l-37.3-18.4L155.5,95.8z M161.9,82.8l37.3,18.4l1.2-2.4l-37.3-18.4L161.9,82.8z
			 M168.4,69.8l37.3,18.4l1.2-2.4l-37.3-18.4L168.4,69.8z M176,54.3l-1.2,2.4l37.3,18.4l1.2-2.4L176,54.3z"/>

                            <rect x="216" y="6.5" transform="matrix(0.4432 -0.8964 0.8964 0.4432 108.3362 204.3397)" className="st1" width="5.3" height="16.9"/>

                            <rect x="217.7" y="5.4" transform="matrix(0.4432 -0.8964 0.8964 0.4432 107.8426 204.3828)" className="st2" width="1.5" height="20"/>
                            <path className="st2" d="M192.8,114.3l-37.3-18.4c-0.7-0.3-1.4-0.1-1.8,0.6c0,0,0,0,0,0l-4.4,8.9c-2.3,4.6-0.4,10.2,4.2,12.5
			l22.9,11.3c4.6,2.3,10.2,0.5,12.6-4.2c0,0,0,0,0,0l4.4-8.9C193.7,115.4,193.5,114.6,192.8,114.3
			C192.8,114.3,192.8,114.3,192.8,114.3z M199.2,101.3l-37.3-18.5c-0.7-0.3-1.4-0.1-1.8,0.6l-4.1,8.2c-0.3,0.7-0.1,1.4,0.6,1.8
			l37.3,18.4c0.7,0.3,1.4,0.1,1.8-0.6l4-8.2C200.1,102.4,199.8,101.6,199.2,101.3L199.2,101.3z M212.1,75.2l-37.3-18.5
			c-0.7-0.3-1.5-0.1-1.8,0.6l-4,8.2c-0.3,0.7-0.1,1.4,0.6,1.8l37.3,18.4c0.7,0.3,1.4,0.1,1.8-0.6l4-8.2
			C213,76.4,212.8,75.6,212.1,75.2C212.1,75.2,212.1,75.2,212.1,75.2L212.1,75.2z M205.6,88.2l-37.3-18.4c-0.7-0.3-1.4-0.1-1.8,0.6
			l-4.1,8.2c-0.3,0.7-0.1,1.4,0.6,1.8l37.3,18.4c0.6,0.3,1.4,0.1,1.8-0.6c0,0,0,0,0,0l4.1-8.2C206.6,89.3,206.3,88.6,205.6,88.2
			L205.6,88.2z M225.1,21.1l-15.1-7.5c-6.2,0.9-11.8,4.3-15.7,6.8c-2.8,1.7-5,4.2-6.4,7.1l-12.4,25c-0.3,0.7-0.1,1.4,0.6,1.8
			l37.3,18.4c0.7,0.3,1.4,0.1,1.8-0.6c0,0,0,0,0,0l12.4-25c1.5-2.9,2.1-6.2,1.8-9.5C228.8,33.1,228.1,26.6,225.1,21.1L225.1,21.1z"
                            />

                            <rect x="188.2" y="27.2" transform="matrix(0.4432 -0.8964 0.8964 0.4432 68.0763 208.2411)" className="st3" width="26.9" height="44.2"/>
                            <path className="st3" d="M218.6,0.9l11.2,5.5c1.8,0.9,2.6,3.2,1.7,5l-2.7,5.5l0,0l-17.9-8.9l0,0l2.7-5.5C214.5,0.8,216.8,0,218.6,0.9
			C218.6,0.9,218.6,0.9,218.6,0.9z"/>
                        </g>
                        <path id="dedosIzq" className="st0" d="M213,74.2c1.9,0.9,4.9,2,6.7-1.7c0.9-1.8,0.2-4.1-1.7-5c0,0,0,0,0,0c-1.9-0.9-5.8-0.2-6.7,1.7
		C210.3,71,211.1,73.2,213,74.2C213,74.2,213,74.2,213,74.2z M207,76.8c-1,1.9-0.7,4.3,2.9,6.1c2.1,1,5.2,2.5,7.3-1.2
		c1-2,0.2-4.4-1.7-5.4c0,0,0,0,0,0C213.5,75.2,208.1,74.9,207,76.8L207,76.8z M211.3,85.5c-4.9-2.4-8.7-1.5-9.6,0.4s1.2,4.6,4.2,6.1
		c2.1,1,5.4,2.5,7.4-1.2C214.2,88.8,213.3,86.5,211.3,85.5L211.3,85.5z M204,92.3c-2.1-1.1-6.3-0.9-7.2,0.8s0.1,3.9,2.1,5
		s5.4,2.5,7.2-0.8C207,95.7,206,93.4,204,92.3z"/>
                        <path className="st0" d="M187,36.1c-7.5,4.1-18.6,11.4-35,12.2C146.8,48.5,0,0.5,0,0.5v52.7c0,0,124.1,22.8,135.1,24.7
		c12.8,2.1,16.2,2.8,19.1,5.8c1.2,1.2,2.7,2.3,4.2,3.2l1.6-3.1c0.5-1,1.3-1.3,2-1l1.2-2.4c-0.7-0.3-0.9-1.1-0.6-1.8l4.1-8.2
		c0.3-0.7,1.1-0.9,1.8-0.6l1.2-2.4c-0.7-0.3-0.9-1.1-0.6-1.8l4-8.2c0.3-0.7,1.1-0.9,1.8-0.6l1.2-2.4c-0.1,0-0.1-0.1-0.2-0.1l1.2-0.8
		c0,0,3.1-1.5,9.7-6.7S190.2,34.4,187,36.1z"/>
                    </g>
                    <g id="brazoDer">
                        <path id="manoDer" className="st0" d="M330,50.8c-7.8,3.6-4.6,4.4-15.5,8.2c-13,4.5-24.9,6.2-32,7.5c-4,0.7-5.1,6.4,3.4,5.8
		s21.4-2.7,21.4-2.7s-2.8,2.3-23.7,6.5c-7.6,1.6-6.6,6.8,1.5,6.5c8.4-0.3,25.4-3.5,25.4-3.5s-12,5.7-21.6,9
		c-7.1,2.4-5.9,7.4,3.9,5.6c10.5-2,22.2-5.6,22.2-5.6s-11.8,6.1-17.1,8.7s-5.3,7.9,4.9,4c4.7-1.9,9.6-3.4,14.5-4.7
		c9.4-2.6,14.9-2.2,27.3-9.3L330,50.8z"/>
                        <g id="botellaDer">
                            <path className="st1" d="M346.4,93.4l-37.3,18.4l1.2,2.4l37.3-18.4L346.4,93.4z M339.9,80.4l-37.3,18.4l1.2,2.4l37.3-18.4L339.9,80.4z
			 M333.5,67.4l-37.3,18.4l1.2,2.4l37.3-18.4L333.5,67.4z M289.7,72.8l1.2,2.4l37.3-18.4l-1.2-2.5L289.7,72.8z"/>

                            <rect x="275.9" y="12.3" transform="matrix(0.8964 -0.4432 0.4432 0.8964 22.8293 127.5857)" className="st1" width="16.9" height="5.3"/>

                            <rect x="274.6" y="14.7" transform="matrix(0.8964 -0.4432 0.4432 0.8964 22.6598 127.7224)" className="st2" width="20" height="1.5"/>
                            <path className="st2" d="M309.7,116.1l4.4,8.9c2.3,4.6,7.9,6.5,12.5,4.2l22.9-11.3c4.6-2.3,6.5-7.9,4.3-12.5c0,0,0,0,0,0l-4.4-8.9
			c-0.3-0.7-1.1-0.9-1.7-0.6c0,0,0,0,0,0l-37.3,18.4C309.6,114.6,309.3,115.4,309.7,116.1z M303.2,103.1l4.1,8.2
			c0.3,0.7,1.1,0.9,1.8,0.6l37.3-18.4c0.7-0.3,0.9-1.1,0.6-1.7c0,0,0,0,0,0l-4.1-8.2c-0.3-0.7-1.1-0.9-1.8-0.6l-37.3,18.4
			C303.2,101.6,302.9,102.4,303.2,103.1L303.2,103.1z M290.3,77l4.1,8.2c0.3,0.7,1.1,0.9,1.8,0.6l37.3-18.4c0.7-0.3,0.9-1.1,0.6-1.7
			c0,0,0,0,0,0l-4.1-8.2c-0.3-0.7-1.1-0.9-1.8-0.6c0,0,0,0,0,0l-37.3,18.4C290.3,75.5,290,76.3,290.3,77C290.3,77,290.3,77,290.3,77
			z M296.8,90l4.1,8.2c0.3,0.7,1.1,0.9,1.7,0.6c0,0,0,0,0,0l37.3-18.4c0.7-0.3,0.9-1.1,0.6-1.7c0,0,0,0,0,0l-4.1-8.2
			c-0.3-0.7-1.1-0.9-1.8-0.6l-37.3,18.4C296.7,88.6,296.5,89.3,296.8,90z M273.8,37.7c-0.3,3.3,0.3,6.5,1.8,9.5l12.4,25
			c0.3,0.7,1.1,0.9,1.8,0.6c0,0,0,0,0,0L327,54.3c0.7-0.3,0.9-1.1,0.6-1.7c0,0,0,0,0,0l-12.4-25c-1.4-2.9-3.7-5.4-6.4-7.1
			c-3.9-2.5-9.5-5.8-15.7-6.8L278,21.1C274.9,26.6,274.2,33.1,273.8,37.7L273.8,37.7z"/>

                            <rect x="279.2" y="35.9" transform="matrix(0.8964 -0.4432 0.4432 0.8964 9.3557 138.6768)" className="st3" width="44.2" height="26.9"/>
                            <path className="st3" d="M292.1,8.2L274.2,17l0,0l-2.7-5.5c-0.9-1.9-0.2-4.1,1.7-5l0,0l11.2-5.5c1.9-0.9,4.1-0.1,5,1.7L292.1,8.2
			L292.1,8.2z"/>
                        </g>
                        <path id="dedosDer" className="st0" d="M291.7,69.2c-0.9-1.8-4.9-2.6-6.7-1.7c-1.9,0.9-2.6,3.1-1.8,5c0,0,0,0,0,0
		c1.8,3.7,4.9,2.6,6.7,1.7C291.9,73.3,292.6,71.1,291.7,69.2C291.7,69.2,291.7,69.2,291.7,69.2z M287.5,76.3c-2,1-2.7,3.4-1.7,5.4
		c0,0,0,0,0,0c2.1,3.8,5.2,2.2,7.3,1.2c3.6-1.8,3.9-4.3,2.9-6.1S289.5,75.2,287.5,76.3L287.5,76.3z M289.7,90.8
		c2,3.7,5.3,2.2,7.4,1.2c3-1.5,5.2-4.3,4.2-6.1s-4.8-2.8-9.6-0.4C289.7,86.4,288.8,88.8,289.7,90.8
		C289.7,90.8,289.7,90.8,289.7,90.8z M296.9,97.3c1.8,3.3,5.1,1.9,7.2,0.8s3-3.3,2.1-5s-5.1-1.9-7.2-0.8S296,95.7,296.9,97.3z"/>
                        <path className="st0" d="M316,36.1c7.5,4.1,18.6,11.4,35,12.2c5.3,0.3,152-47.7,152-47.7v52.7c0,0-124,22.8-135.1,24.7
		c-12.8,2.1-16.2,2.8-19.1,5.8c-1.2,1.2-2.7,2.3-4.2,3.2l-1.5-3.1c-0.5-1-1.3-1.3-2-1l-1.2-2.4c0.7-0.3,0.9-1.1,0.6-1.7c0,0,0,0,0,0
		l-4.1-8.2c-0.3-0.7-1.1-0.9-1.8-0.6l-1.2-2.4c0.7-0.3,0.9-1.1,0.6-1.7c0,0,0,0,0,0l-4-8.3c-0.3-0.7-1.1-0.9-1.8-0.6c0,0,0,0,0,0
		l-1.2-2.4c0.1,0,0.1-0.1,0.2-0.1l-1.2-0.8c0,0-3.1-1.5-9.7-6.7S312.8,34.4,316,36.1z"/>
                    </g>
                    <path className="st3" d="M255,165.6c-2.3-2.1-8-4.2-14.2-6.1s-12.1-3.3-15.2-2.9s-28.5,11.8-41,47.8l-7.7,25.3l77.8,23.8l7.6-25.3
	l0.5,0.1C272.2,191.4,257.2,167.6,255,165.6z"/>
                    <path className="st4" d="M254.7,152.4l-21.6-6.6l5.1-12.4c0.2-0.7,0.9-1.1,1.6-1l16.8,5.1c0.6,0.3,1,1,0.8,1.7L254.7,152.4z"/>
                    <path className="st5" d="M254.2,165l-27.7-8.5l5.2-11.5c0.3-0.7,1.1-1.1,1.9-0.9l21.5,6.6c0.8,0.2,1.2,1,1.1,1.8L254.2,165z"/>
                    <ellipse transform="matrix(2.704936e-02 -0.9996 0.9996 2.704936e-02 48.1871 447.2952)" className="st6" cx="253.9" cy="198.9" rx="16.6" ry="5.7"/>
                    <path className="st7" d="M164,192.2c-0.8,0.6-1.2,5.6-1.3,7.7s3.3,15.9,3.3,15.9h50.7c0,0-4.1-19-5.8-23.6s-5.5-9.8-6.5-10.4
	s-2.2,0-4.5,0.5S165,191.4,164,192.2z"/>
                    <path className="st8" d="M175.4,215.8c0,0-2.5-9.7-3.3-12.8s0.6-4.5,5.6-5.9s15.7-3.9,19-4.8c4.1-1.1,4.9,1,5.9,4.2
	c1.5,4.6,5.5,19.4,5.5,19.4H175.4z"/>
                    <path className="st9" d="M199.2,198l-23.4,6l0.3,1.1l23.4-6.1L199.2,198z M200.4,202.8l-23.4,6l0.3,1.1l23.4-6L200.4,202.8z
	 M201.6,207.3l-23.4,6.1l0.3,1.1l23.4-6.1L201.6,207.3z M202.8,212l-14.7,3.8h4.5l10.5-2.7L202.8,212z"/>
                    <path className="st4" d="M177.6,184.2c-3.2-12.1-11.3-21-19.9-22.8c-0.7-0.2-1.3-0.7-1.5-1.4l-1.3-5l-10.8,2.8l1.3,5
	c0.2,0.7,0,1.5-0.6,2c-6.6,5.8-9.3,17.5-6.1,29.7c1.3,5.1,3.6,9.8,6.8,13.9c1.3,1.6,2.2,3.4,2.7,5.3l4.2,15.9l30.6-8.1l-4.2-15.9
	c-0.5-2-0.6-4-0.3-6C179.3,194.5,178.9,189.2,177.6,184.2z"/>
                    <path className="st3" d="M142.9,152.5l10.2-2.7c0.9-0.2,1.8,0.3,2,1.2l1.5,5.8l0,0l-13.4,3.5l0,0l-1.5-5.8
	C141.5,153.6,142.1,152.7,142.9,152.5z"/>
                    <path className="st10" d="M349,138.9l15.1,7.8c0.8,0.4,1.1,1.4,0.7,2.1l-2.9,5.7l0,0l-17.9-9.3l0,0l2.9-5.7
	C347.3,138.8,348.2,138.5,349,138.9z"/>
                    <path className="st11" d="M362.1,209.6c0.4-6.2-3.7-3.6-0.1-16.5s6.6-21.4,5.1-26.5c-0.8-2.8-2.3-3.4-6-5.7c-2.2-1.4-3.5-2.7-3-5.3
	l0.2-0.9l-15-7.9l-1,1.8c-0.9,1.8-2.4,3.2-4.1,4.2c-8,4.4-31.7,18-37.3,28.7c-6.8,12.9-24.4,45.9-24.4,45.9l59.4,31.2
	c0,0,17.1-32,19.7-36.9S361.7,215.8,362.1,209.6z M353.9,195.9c-1.1,1.6-2.6,2.3-4,1.7c0,0-0.3-5.7,1.9-20.1
	c1.8-11.5,1.9-11,4.1-11.3c2.4-0.2,4.5,1.6,4.7,4c0,0.2,0,0.5,0,0.7c-0.3,3.6-1,7.2-2,10.7C356.9,188.4,355,194.3,353.9,195.9z"/>
                    <path className="st12" d="M249.1,189.5c0,0-19.7,36.1-22.6,41.6c-3.9,7.4,4.2,14.6,9.4,9.4l64.1-64.1c5.2-5.2-2-13.4-9.4-9.4
	C285.3,169.8,249.2,189.5,249.1,189.5l-2.7-2.7c-1.1-1.1-14,1.6-14.1-8.5c0-7.2,9-7.4,11.2-4.4"/>
                    <path className="st13" d="M283.7,174.1l-7.2,4.9c0,0,6.1,5.2,6.1,15.4h15.3c0,0,0.7-7.9,17.1-11.4v-8.5L283.7,174.1z"/>
                    <rect x="281" y="194.3" className="st14" width="18.1" height="15.2"/>
                    <rect x="315" y="172.8" className="st14" width="6.6" height="11.4"/>
                    <path className="st7" d="M301.3,215.8h-22.5l0.9-5.7c0-0.3,0.3-0.5,0.6-0.5h19.4c0.3,0,0.6,0.2,0.6,0.5L301.3,215.8z"/>
                    <path className="st11" d="M126.5,227.4h251.6c0.7,0,1.3,0.6,1.3,1.3v15c0,0.7-0.6,1.3-1.3,1.3H126.5c-0.7,0-1.3-0.6-1.3-1.3v-15
	C125.2,228,125.7,227.4,126.5,227.4z"/>
                    <path className="st15" d="M152.3,335.2h200l1.6-90.2H150.6L152.3,335.2z M359.7,215.8H144.8c-1,0-1.9,0.8-1.9,1.9v9.8h218.6v-9.8
	C361.6,216.6,360.7,215.8,359.7,215.8z"/>
                    <path className="st16" d="M134.9,245l16.3,25.7l-0.5-25.7H134.9z M353.8,245l-0.2,25.7l16.1-25.7H353.8z M310.6,262.1h4V245h-4V262.1z
	 M189.9,262.1h4V245h-4V262.1z M250.2,262.1h4V245h-4V262.1z"/>
                    <path className="st17" d="M255,275c2.3-4.1,3.8-4.3,3.8-4.3h-13.4c-2.5,0-5.7,0.5-7.6,3.6s-6.6,11.4-6.6,11.4l13.2,7.6
	C244.4,293.4,252.9,278.5,255,275z M257.4,274.4c-0.6,1-4.2,7.2-4.2,7.2l5.8,10.3l-3.6,2.2l14.1,0.6L276,282l-3.7,2.1
	c0,0-4.7-8.1-5.9-10c-0.8-1.2-1.9-2.1-3.2-2.7C260.8,271.2,258.6,272.3,257.4,274.4L257.4,274.4z M284.9,306
	c-1.9-3.4-6.5-11.4-6.5-11.4l-13.2,7.6l10.6,18.4c2.3,4.1,1.8,5.5,1.8,5.5l6.7-11.6C285.5,312.3,286.7,309.3,284.9,306z M271,315.7
	l-11.9-0.1v-4.2l-7.6,11.9l7.4,11.9V331c0,0,9.4,0,11.6,0c1.4-0.1,2.8-0.6,4-1.5c1.4-1.9,1.5-4.5,0.4-6.5
	C274.5,321.9,271,315.7,271,315.7z M220.4,314.4l6.7,11.6c1.3,2.2,3.2,4.8,7,4.8s13.2,0.1,13.2,0.1v-15.3h-21.2
	C221.3,315.6,220.4,314.4,220.4,314.4L220.4,314.4z M232.6,314l6-10.2l3.6,2.1l-6.5-12.5l-14,0.6l3.6,2.1c0,0-4.7,8.1-5.8,10.1
	c-0.6,1.3-0.9,2.7-0.7,4.2c1,2.1,3.1,3.5,5.5,3.6C225.4,313.9,232.6,314,232.6,314L232.6,314z"/>
                </svg>

            </div>
        );
    }
}

export default Reciclaje;